import Button from 'components/Button';
import { ErrorMessage, Field, Form } from 'formik';
import { SectionForm, SectionFormValues } from '../SectionForm';
import { ReactComponent as FlocklerLogo } from 'images/flockler-logo.svg';
import { AbsoluteErrorMessage } from 'components/AbsoluteErrorMessage';
import { nameLabel } from 'services/labels';
import capitalize from 'lodash/capitalize';

interface SectionsCreateFirstSectionProps {
  entityName: string;
  onSubmit: (payload: SectionFormValues) => void;
}

const initialValues: SectionFormValues = {
  name: '',
};

export const SectionsCreateFirstSection = ({ entityName, onSubmit }: SectionsCreateFirstSectionProps) => {
  return (
    <SectionForm initialValues={initialValues} onSubmit={onSubmit}>
      {({ isSubmitting, isValid, dirty }) => (
        <Form>
          <div className="mx-auto max-w-xl">
            <div className="mb-10 mt-6 space-y-5 text-center">
              <FlocklerLogo className="mb-5 h-auto w-24" />

              <h2 className="text-2xl font-bold">Time to create your first {entityName}!</h2>

              <p className="mt-4">
                All content on Flockler is posted to {entityName}s. {capitalize(entityName)}s can be topics, themes,
                <br />
                or categories, or you can simply create just one {entityName} (Articles, Social etc.).
              </p>
            </div>

            <div className="mx-auto flex w-full max-w-md">
              <div className="relative mr-4 flex-grow">
                <Field
                  aria-label={nameLabel(entityName)}
                  placeholder={nameLabel(entityName)}
                  name="name"
                  type="text"
                  autoComplete="off"
                />
                <ErrorMessage name="name" component={AbsoluteErrorMessage} />
              </div>
              <Button className="flex-shrink-0" type="submit" disabled={isSubmitting || !isValid || !dirty}>
                <span>Create {entityName}</span>
              </Button>
            </div>
          </div>
        </Form>
      )}
    </SectionForm>
  );
};
