import Button from 'components/Button';
import Modal from 'components/Modal';

import styles from './AutomatedFeedDeleteModal.module.scss';

type AutomatedFeedDeleteModalProps = {
  deleteAction: () => void;
  deleteWithContentAction: () => void;
  dismissAction: () => void;
};

const AutomatedFeedDeleteModal = ({
  deleteAction,
  deleteWithContentAction,
  dismissAction,
}: AutomatedFeedDeleteModalProps) => {
  return (
    <Modal
      title="Delete automated feed?"
      actionButtons={[
        <div key="DeleteFeedCancel" className={styles.cancelAction}>
          <Button variant="neutral" size="small" action={dismissAction}>
            Cancel
          </Button>
        </div>,
        <div key="DeleteFeedActions" className={styles.deleteActions}>
          <Button variant="primary" size="small" action={deleteAction}>
            Delete Feed only
          </Button>
          <Button variant="danger" size="small" action={deleteWithContentAction}>
            Delete Feed and its contents
          </Button>
        </div>,
      ]}
      dismissAction={dismissAction}
    >
      You can either delete the feed and keep the content that you’ve stored to the section (recommended), or delete the
      feed and all the content it has stored so far.
    </Modal>
  );
};

export default AutomatedFeedDeleteModal;
