import { useState } from 'react';
import * as api from 'services/api';
import { Helmet } from 'react-helmet';

import Button from 'components/Button';
import Heading from 'components/Heading';
import Page from 'components/Page';
import { SectionPage } from 'hooks/api/useSections';

const ReviewFormOnlySectionConfirm = ({
  sections,
  site,
  onSelect,
  mutateSections,
}: {
  sections: Section[];
  site: Site;
  onSelect: (sectionUuid: string) => void;
  mutateSections: (func: any) => void;
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [isSectionCreateVisible, setIsSectionCreateVisible] = useState(false);

  return (
    <Page style={{ maxWidth: '56rem' }}>
      <Helmet>
        <title>Flockler {'\u203A'} Reviews</title>
      </Helmet>

      <Heading level="h1" type="primary">
        Which section would you like to store the reviews?
      </Heading>

      <div className="mx-auto max-w-lg rounded bg-slate-50 p-6">
        <p className="mb-4">
          Would you like to store reviews to the same section <strong>“{sections[0].name}”</strong> with other content
          or create a new one for reviews?
        </p>

        <div className="flex">
          <Button
            data-button-variant="success"
            action={() => onSelect(sections[0].uuid)}
            className="w-1/2 !px-6"
            size="medium"
          >
            Use the same section
          </Button>
          <Button
            data-button-variant="primary"
            action={() => setIsSectionCreateVisible(true)}
            className="w-1/2 !px-6"
            size="medium"
          >
            Create new
          </Button>
        </div>

        {isSectionCreateVisible && (
          <form
            className="flex animate-fade-in items-end justify-between"
            onSubmit={async (e) => {
              e.preventDefault();

              if (inputValue) {
                await mutateSections(async (pages: SectionPage[]) => {
                  const { data: section } = await api.createSection(site.id, { name: inputValue });

                  onSelect(section.uuid);

                  if (pages) {
                    const [page, ...rest] = pages;
                    return [{ ...page, sections: [section, ...page.sections] }, ...rest];
                  }
                });
              }
            }}
          >
            <div className={`flex-1 transition-all ${!!inputValue ? 'mr-2' : ''}`}>
              <label className="mt-4" htmlFor="sectionCreateInput">
                Create a new section
              </label>
              <input
                required
                autoFocus
                className="input"
                id="sectionCreateInput"
                type="text"
                placeholder="Section name…"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />
            </div>

            {!!inputValue && (
              <Button data-button-variant="success" type="submit" className="animate-fade-in !px-8" size="medium">
                Create
              </Button>
            )}
          </form>
        )}
      </div>
    </Page>
  );
};

export default ReviewFormOnlySectionConfirm;
