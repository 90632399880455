import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import { Helmet } from 'react-helmet';
import { Prompt } from 'react-router';
import { useFormik } from 'formik';

import { getCurrentSite, updateSite, getSitesLoadingStatus, getSiteUpdateRequestStatus } from 'concepts/site';

import Button from 'components/Button';
import FormStatusDetail from 'components/FormStatusDetail';
import HelpText from 'components/HelpText';
import HelpScoutLink from 'components/HelpScoutLink';
import LinkExternal from 'components/LinkExternal';

import Icon from 'components/Icon';

type SiteSettingsProps = {
  site: Site;
  updateSite: any;
  isLoadingSite: boolean;
  siteUpdateRequestStatus: RequestStatusType;
};

const SiteSettings = ({ site, updateSite, isLoadingSite, siteUpdateRequestStatus }: SiteSettingsProps) => {
  const shouldDisableForm = isLoadingSite || siteUpdateRequestStatus === 'loading';

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: site?.name || '',
      locale: site?.locale || 'en',
      time_zone: site?.time_zone || 'London',
    },
    onSubmit: (values) => {
      updateSite(site.id, values).catch((error: any) => console.log('error', error));
    },
  });

  const hasUnsavedChanges = formik.dirty;

  return (
    <>
      <Helmet>
        <title>
          Flockler {'\u203A'} {site ? 'Site settings' : 'Loading…'}
        </title>
      </Helmet>
      <Prompt when={hasUnsavedChanges} message="You have unsaved changes, are you sure you want to leave?" />

      <form onSubmit={formik.handleSubmit} className="grid grid-cols-2 gap-6">
        <div className="col-span-2">
          <label htmlFor="siteName">Site name</label>
          <input
            id="siteName"
            className="input--large"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            disabled={shouldDisableForm}
            placeholder={isLoadingSite ? 'Loading…' : ''}
            type="text"
            minLength={1}
            required
          />
        </div>

        <div className="col-span-2 sm:col-span-1">
          <label htmlFor="defaultLanguage">Default language for embeds</label>
          <select
            id="defaultLanguage"
            name="locale"
            value={formik.values.locale}
            onChange={formik.handleChange}
            disabled={shouldDisableForm}
          >
            <option value="en">English</option>
            <option value="ar">Arabic</option>
            <option value="zh-HK">Chinese (Hong Kong)</option>
            <option value="zh">Chinese (Simplified)</option>
            <option value="zh-TW">Chinese (Traditional)</option>
            <option value="cs">Czech</option>
            <option value="da">Danish</option>
            <option value="nl">Dutch</option>
            <option value="fi">Finnish</option>
            <option value="fr">French</option>
            <option value="de">German</option>
            <option value="he">Hebrew</option>
            <option value="hu">Hungarian</option>
            <option value="id">Indonesian</option>
            <option value="it">Italian</option>
            <option value="ja">Japanese</option>
            <option value="ko">Korean</option>
            <option value="nb">Norwegian</option>
            <option value="pl">Polish</option>
            <option value="pt">Portuguese</option>
            <option value="ro">Romanian</option>
            <option value="ru">Russian</option>
            <option value="sk">Slovak</option>
            <option value="es">Spanish</option>
            <option value="sv">Swedish</option>
            <option value="th">Thai</option>
            <option value="tr">Turkish</option>
            <option value="vi">Vietnamese</option>
          </select>

          <div style={{ marginTop: '.5rem' }}>
            <HelpText>
              Don’t see your language here?
              <br />
              <HelpScoutLink className="text-brand underline">Send us a message</HelpScoutLink>, and we'll add it for you.
            </HelpText>
          </div>
        </div>

        <div className="col-span-2 sm:col-span-1">
          <label htmlFor="timezone">Timezone</label>
          <select
            id="timezone"
            name="time_zone"
            value={formik.values.time_zone}
            onChange={formik.handleChange}
            disabled={shouldDisableForm}
          >
            <option value="American Samoa">(GMT-11:00) American Samoa</option>
            <option value="International Date Line West">(GMT-11:00) International Date Line West</option>
            <option value="Midway Island">(GMT-11:00) Midway Island</option>
            <option value="Hawaii">(GMT-10:00) Hawaii</option>
            <option value="Alaska">(GMT-09:00) Alaska</option>
            <option value="Pacific Time (US &amp; Canada)">(GMT-08:00) Pacific Time (US &amp; Canada)</option>
            <option value="Tijuana">(GMT-08:00) Tijuana</option>
            <option value="Arizona">(GMT-07:00) Arizona</option>
            <option value="Chihuahua">(GMT-07:00) Chihuahua</option>
            <option value="Mazatlan">(GMT-07:00) Mazatlan</option>
            <option value="Mountain Time (US &amp; Canada)">(GMT-07:00) Mountain Time (US &amp; Canada)</option>
            <option value="Central America">(GMT-06:00) Central America</option>
            <option value="Central Time (US &amp; Canada)">(GMT-06:00) Central Time (US &amp; Canada)</option>
            <option value="Guadalajara">(GMT-06:00) Guadalajara</option>
            <option value="Mexico City">(GMT-06:00) Mexico City</option>
            <option value="Monterrey">(GMT-06:00) Monterrey</option>
            <option value="Saskatchewan">(GMT-06:00) Saskatchewan</option>
            <option value="Bogota">(GMT-05:00) Bogota</option>
            <option value="Eastern Time (US &amp; Canada)">(GMT-05:00) Eastern Time (US &amp; Canada)</option>
            <option value="Indiana (East)">(GMT-05:00) Indiana (East)</option>
            <option value="Lima">(GMT-05:00) Lima</option>
            <option value="Quito">(GMT-05:00) Quito</option>
            <option value="Caracas">(GMT-04:30) Caracas</option>
            <option value="Atlantic Time (Canada)">(GMT-04:00) Atlantic Time (Canada)</option>
            <option value="Georgetown">(GMT-04:00) Georgetown</option>
            <option value="La Paz">(GMT-04:00) La Paz</option>
            <option value="Santiago">(GMT-04:00) Santiago</option>
            <option value="Newfoundland">(GMT-03:30) Newfoundland</option>
            <option value="Brasilia">(GMT-03:00) Brasilia</option>
            <option value="Buenos Aires">(GMT-03:00) Buenos Aires</option>
            <option value="Greenland">(GMT-03:00) Greenland</option>
            <option value="Montevideo">(GMT-03:00) Montevideo</option>
            <option value="Mid-Atlantic">(GMT-02:00) Mid-Atlantic</option>
            <option value="Azores">(GMT-01:00) Azores</option>
            <option value="Cape Verde Is.">(GMT-01:00) Cape Verde Is.</option>
            <option value="Casablanca">(GMT+00:00) Casablanca</option>
            <option value="Dublin">(GMT+00:00) Dublin</option>
            <option value="Edinburgh">(GMT+00:00) Edinburgh</option>
            <option value="Lisbon">(GMT+00:00) Lisbon</option>
            <option value="London">(GMT+00:00) London</option>
            <option value="Monrovia">(GMT+00:00) Monrovia</option>
            <option value="UTC">(GMT+00:00) UTC</option>
            <option value="Amsterdam">(GMT+01:00) Amsterdam</option>
            <option value="Belgrade">(GMT+01:00) Belgrade</option>
            <option value="Berlin">(GMT+01:00) Berlin</option>
            <option value="Bern">(GMT+01:00) Bern</option>
            <option value="Bratislava">(GMT+01:00) Bratislava</option>
            <option value="Brussels">(GMT+01:00) Brussels</option>
            <option value="Budapest">(GMT+01:00) Budapest</option>
            <option value="Copenhagen">(GMT+01:00) Copenhagen</option>
            <option value="Ljubljana">(GMT+01:00) Ljubljana</option>
            <option value="Madrid">(GMT+01:00) Madrid</option>
            <option value="Paris">(GMT+01:00) Paris</option>
            <option value="Prague">(GMT+01:00) Prague</option>
            <option value="Rome">(GMT+01:00) Rome</option>
            <option value="Sarajevo">(GMT+01:00) Sarajevo</option>
            <option value="Skopje">(GMT+01:00) Skopje</option>
            <option value="Stockholm">(GMT+01:00) Stockholm</option>
            <option value="Vienna">(GMT+01:00) Vienna</option>
            <option value="Warsaw">(GMT+01:00) Warsaw</option>
            <option value="West Central Africa">(GMT+01:00) West Central Africa</option>
            <option value="Zagreb">(GMT+01:00) Zagreb</option>
            <option value="Athens">(GMT+02:00) Athens</option>
            <option value="Bucharest">(GMT+02:00) Bucharest</option>
            <option value="Cairo">(GMT+02:00) Cairo</option>
            <option value="Harare">(GMT+02:00) Harare</option>
            <option value="Helsinki">(GMT+02:00) Helsinki</option>
            <option value="Istanbul">(GMT+02:00) Istanbul</option>
            <option value="Jerusalem">(GMT+02:00) Jerusalem</option>
            <option value="Kyiv">(GMT+02:00) Kyiv</option>
            <option value="Pretoria">(GMT+02:00) Pretoria</option>
            <option value="Riga">(GMT+02:00) Riga</option>
            <option value="Sofia">(GMT+02:00) Sofia</option>
            <option value="Tallinn">(GMT+02:00) Tallinn</option>
            <option value="Vilnius">(GMT+02:00) Vilnius</option>
            <option value="Baghdad">(GMT+03:00) Baghdad</option>
            <option value="Kaliningrad">(GMT+03:00) Kaliningrad</option>
            <option value="Kuwait">(GMT+03:00) Kuwait</option>
            <option value="Minsk">(GMT+03:00) Minsk</option>
            <option value="Nairobi">(GMT+03:00) Nairobi</option>
            <option value="Riyadh">(GMT+03:00) Riyadh</option>
            <option value="Tehran">(GMT+03:30) Tehran</option>
            <option value="Abu Dhabi">(GMT+04:00) Abu Dhabi</option>
            <option value="Baku">(GMT+04:00) Baku</option>
            <option value="Moscow">(GMT+04:00) Moscow</option>
            <option value="Muscat">(GMT+04:00) Muscat</option>
            <option value="Samara">(GMT+04:00) Samara</option>
            <option value="St. Petersburg">(GMT+04:00) St. Petersburg</option>
            <option value="Tbilisi">(GMT+04:00) Tbilisi</option>
            <option value="Volgograd">(GMT+04:00) Volgograd</option>
            <option value="Yerevan">(GMT+04:00) Yerevan</option>
            <option value="Kabul">(GMT+04:30) Kabul</option>
            <option value="Islamabad">(GMT+05:00) Islamabad</option>
            <option value="Karachi">(GMT+05:00) Karachi</option>
            <option value="Tashkent">(GMT+05:00) Tashkent</option>
            <option value="Chennai">(GMT+05:30) Chennai</option>
            <option value="Kolkata">(GMT+05:30) Kolkata</option>
            <option value="Mumbai">(GMT+05:30) Mumbai</option>
            <option value="New Delhi">(GMT+05:30) New Delhi</option>
            <option value="Sri Jayawardenepura">(GMT+05:30) Sri Jayawardenepura</option>
            <option value="Kathmandu">(GMT+05:45) Kathmandu</option>
            <option value="Almaty">(GMT+06:00) Almaty</option>
            <option value="Astana">(GMT+06:00) Astana</option>
            <option value="Dhaka">(GMT+06:00) Dhaka</option>
            <option value="Ekaterinburg">(GMT+06:00) Ekaterinburg</option>
            <option value="Rangoon">(GMT+06:30) Rangoon</option>
            <option value="Bangkok">(GMT+07:00) Bangkok</option>
            <option value="Hanoi">(GMT+07:00) Hanoi</option>
            <option value="Jakarta">(GMT+07:00) Jakarta</option>
            <option value="Novosibirsk">(GMT+07:00) Novosibirsk</option>
            <option value="Beijing">(GMT+08:00) Beijing</option>
            <option value="Chongqing">(GMT+08:00) Chongqing</option>
            <option value="Hong Kong">(GMT+08:00) Hong Kong</option>
            <option value="Krasnoyarsk">(GMT+08:00) Krasnoyarsk</option>
            <option value="Kuala Lumpur">(GMT+08:00) Kuala Lumpur</option>
            <option value="Perth">(GMT+08:00) Perth</option>
            <option value="Singapore">(GMT+08:00) Singapore</option>
            <option value="Taipei">(GMT+08:00) Taipei</option>
            <option value="Ulaanbaatar">(GMT+08:00) Ulaanbaatar</option>
            <option value="Urumqi">(GMT+08:00) Urumqi</option>
            <option value="Irkutsk">(GMT+09:00) Irkutsk</option>
            <option value="Osaka">(GMT+09:00) Osaka</option>
            <option value="Sapporo">(GMT+09:00) Sapporo</option>
            <option value="Seoul">(GMT+09:00) Seoul</option>
            <option value="Tokyo">(GMT+09:00) Tokyo</option>
            <option value="Adelaide">(GMT+09:30) Adelaide</option>
            <option value="Darwin">(GMT+09:30) Darwin</option>
            <option value="Brisbane">(GMT+10:00) Brisbane</option>
            <option value="Canberra">(GMT+10:00) Canberra</option>
            <option value="Guam">(GMT+10:00) Guam</option>
            <option value="Hobart">(GMT+10:00) Hobart</option>
            <option value="Melbourne">(GMT+10:00) Melbourne</option>
            <option value="Port Moresby">(GMT+10:00) Port Moresby</option>
            <option value="Sydney">(GMT+10:00) Sydney</option>
            <option value="Yakutsk">(GMT+10:00) Yakutsk</option>
            <option value="New Caledonia">(GMT+11:00) New Caledonia</option>
            <option value="Solomon Is.">(GMT+11:00) Solomon Is.</option>
            <option value="Vladivostok">(GMT+11:00) Vladivostok</option>
            <option value="Auckland">(GMT+12:00) Auckland</option>
            <option value="Fiji">(GMT+12:00) Fiji</option>
            <option value="Kamchatka">(GMT+12:00) Kamchatka</option>
            <option value="Magadan">(GMT+12:00) Magadan</option>
            <option value="Marshall Is.">(GMT+12:00) Marshall Is.</option>
            <option value="Wellington">(GMT+12:00) Wellington</option>
            <option value="Chatham Is.">(GMT+12:45) Chatham Is.</option>
            <option value="Nuku'alofa">(GMT+13:00) Nuku'alofa</option>
            <option value="Samoa">(GMT+13:00) Samoa</option>
            <option value="Tokelau Is.">(GMT+13:00) Tokelau Is.</option>
          </select>
        </div>

        <div className="form__submit-row col-span-2 mt-4">
          <Button type="submit" disabled={shouldDisableForm} className="mr-5">
            {siteUpdateRequestStatus === 'loading' ? 'Saving changes…' : 'Save changes'}
          </Button>
          {siteUpdateRequestStatus !== 'loading' && siteUpdateRequestStatus !== 'failure' && hasUnsavedChanges && (
            <FormStatusDetail>
              <Icon type="warning-circle" />
              <span>You have unsaved changes</span>
            </FormStatusDetail>
          )}

          {siteUpdateRequestStatus === 'failure' && (
            <FormStatusDetail level="danger">
              <Icon type="warning-circle" />
              <span>Saving changes failed</span>
            </FormStatusDetail>
          )}

          {siteUpdateRequestStatus === 'success' && !hasUnsavedChanges && (
            <FormStatusDetail level="success">
              <Icon type="checkmark-circle" />
              <span>Changes saved</span>
            </FormStatusDetail>
          )}
        </div>
      </form>

      <div className="mt-8">
        <hr />
      </div>

      <div className="mt-5 font-mono text-xs opacity-50 transition-opacity duration-700 hover:opacity-100">
        <span className="my-2 mr-5 inline-block font-semibold">
          <LinkExternal href="https://developers.flockler.com/api">API Documentation</LinkExternal>
        </span>
        <span className="my-2 mr-5 inline-block">
          <span className="mr-2 font-semibold">Site-ID</span> {site?.id}
        </span>
        <span className="my-2 mr-5 inline-block">
          <span className="mr-2 font-semibold">Site-UUID</span> {site?.uuid}
        </span>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  site: getCurrentSite(state),
  isLoadingSite: getSitesLoadingStatus(state),
  siteUpdateRequestStatus: getSiteUpdateRequestStatus(state),
});

const mapDispatchToProps = {
  updateSite,
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteSettings);
