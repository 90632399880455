import React from 'react';
import classnames from 'classnames';
import copyToClipboard from 'services/copy-to-clipboard';

import Icon from 'components/Icon';
import { getShortEmbedIframeCode } from 'services/embed-iframe-code';
import IframeCopyModal from '../IframeCopyModal';
import styles from './EmbedCodeCopier.module.scss';

const CopyButton = ({ isCopied, onClick, children }: any) => {
  return (
    <button className={classnames(styles.embedCodeActionButton, { [styles.success]: isCopied })} onClick={onClick}>
      {isCopied ? (
        <span className={styles.buttonText}>
          <Icon type="checkmark" className={styles.icon} /> Copied to clipboard
        </span>
      ) : (
        children
      )}
    </button>
  );
};

type EmbedCodeCopierProps = {
  embedCode?: string;
  className?: string;
  size?: 'large';
  siteUuid: string;
  embedStyle?: string;
  embedUuid?: string;
  embedConfig: EmbedConfiguration | SlideshowConfiguration;
};

const EmbedCodeCopier = ({
  embedCode,
  embedUuid,
  embedStyle,
  siteUuid,
  className,
  size,
  embedConfig,
}: EmbedCodeCopierProps) => {
  const [isCopied, setIsCopied] = React.useState(false);
  const [selectedCodeType, setSelectedCodeType] = React.useState<EmbedCodeType>('default');
  const [isIframeCopyModalOpen, setIIsframeCopyModalOpen] = React.useState<boolean>(false);
  const embedCodeRef = React.createRef<HTMLDivElement>();
  const copy = () => copyToClipboard(embedCodeRef, setIsCopied);

  return (
    <>
      {isIframeCopyModalOpen && (
        <IframeCopyModal
          dismissAction={() => setIIsframeCopyModalOpen(false)}
          embedStyle={embedStyle}
          embedUuid={embedUuid}
          siteUuid={siteUuid}
          embedConfig={embedConfig}
        />
      )}
      <div className={classnames(styles.tabs, { [styles.largeMode]: size === 'large' })}>
        <button
          onClick={() => setSelectedCodeType('default')}
          className={classnames(styles.tab, styles.default, { [styles.selected]: selectedCodeType === 'default' })}
          title="Show the default JS option"
        >
          Default
        </button>
        <button
          onClick={() => setSelectedCodeType('iframe')}
          className={classnames(styles.tab, { [styles.selected]: selectedCodeType === 'iframe' })}
          title="Show the iframe option"
        >
          iframe
        </button>
      </div>
      <div
        onClick={selectedCodeType === 'iframe' ? () => setIIsframeCopyModalOpen(true) : copy}
        className={classnames(
          styles.embed,
          { [styles.embedSuccess]: isCopied, [styles.large]: size === 'large' },
          className
        )}
      >
        <div ref={embedCodeRef} className={classnames(styles.embedCode, 'notranslate')}>
          {selectedCodeType === 'default' && embedCode}
          {selectedCodeType === 'iframe' && embedUuid && getShortEmbedIframeCode(siteUuid, embedUuid)}
        </div>
        <div className={styles.embedCodeAction}>
          {selectedCodeType === 'default' && (
            <CopyButton onClick={copy} isCopied={isCopied}>
              Copy the Embed code
            </CopyButton>
          )}

          {selectedCodeType === 'iframe' && (
            <CopyButton onClick={() => setIIsframeCopyModalOpen(true)}>Copy the iframe code</CopyButton>
          )}
        </div>
      </div>
    </>
  );
};

export default EmbedCodeCopier;
