import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import useSiteId from 'hooks/useSiteId';
import useSiteUrl from 'hooks/useSiteUrl';
import { apiClient, CsrfToken, PATHS } from 'services/api';
import { pathToAutomatedFeeds } from 'services/routes';

import Button from 'components/Button';
import Heading from 'components/Heading';
import HelpText from 'components/HelpText';
import Icon from 'components/Icon';
import LoadingIndicator from 'components/Loader/LoadingIndicator';
import useSWR, { useSWRConfig } from 'swr';
import config from 'config';

export type MediaTrackerSiteFilter = {
  id: number;
  site_id: number;
  filter_type: 'exclude_author' | 'exclude_keyword';
  target_service: 'facebook' | 'instagram_graph_api' | 'twitter';
  parameters: {
    name: 'author' | 'keyword';
    value: string;
  }[];
};

export type MediaTrackerSiteFilterIndexResponse = {
  media_tracker_site_filters: MediaTrackerSiteFilter[];
};

export type BlacklistItem = {
  id: number;
  channel: MediaTrackerSiteFilter['target_service'];
  type: MediaTrackerSiteFilter['filter_type'];
  value: string;
};

class BlacklistError extends Error {
  constructor(message: string) {
    super(message); // (1)
    this.name = 'BlacklistError'; // (2)
  }
}

const Blacklist = () => {
  const siteUrl = useSiteUrl();
  const siteId = useSiteId();

  const [isCreating, setIsCreating] = useState(false);

  const [newBlacklistItem, setNewBlacklistItem] = useState<Omit<BlacklistItem, 'id'>>({
    channel: 'facebook',
    type: 'exclude_author',
    value: '',
  });

  const { mutate } = useSWRConfig();
  const { data } = useSWR<MediaTrackerSiteFilterIndexResponse>(
    siteId ? PATHS.MEDIA_TRACKER_SITE_FILTERS(siteId) : null,
    apiClient.get
  );

  const orderedBlacklistItems = [...(data?.media_tracker_site_filters || [])].reverse();

  const addItem = (e: React.FormEvent) => {
    e.preventDefault();
    if (isCreating) return false;

    setIsCreating(true);

    const values = newBlacklistItem.value
      .split(',')
      .map((v) => v.trim())
      .filter((v) => v.length);
    try {
      const pendingCreates = values.map((value) => {
        let trimmedValue = value;

        if (newBlacklistItem.type === 'exclude_author') {
          trimmedValue = trimmedValue.replaceAll('@', '');
        }

        if (
          orderedBlacklistItems.find(
            (item: MediaTrackerSiteFilter) =>
              newBlacklistItem.channel === item.target_service &&
              newBlacklistItem.type === item.filter_type &&
              trimmedValue === item.parameters[0].value
          )
        ) {
          throw new BlacklistError(`"${trimmedValue}" already exists in the blacklist`);
        }

        const createBlacklistItem = async () => {
          const response = await fetch(`${config.flocklerPrivateApiUrl}/sites/${siteId}/media_tracker_site_filters`, {
            method: 'POST',
            credentials: 'include',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-CSRF-TOKEN': CsrfToken.get(),
            },
            body: JSON.stringify({
              media_tracker_site_filter: {
                filter_type: newBlacklistItem.type,
                target_service: newBlacklistItem.channel,
                parameters_attributes: [
                  {
                    name: {
                      exclude_author: 'name',
                      exclude_keyword: 'keyword',
                    }[newBlacklistItem.type],
                    value: trimmedValue,
                  },
                ],
              },
            }),
          });
          const responseJson = await response.json();
          return responseJson.media_tracker_site_filter;
        };

        return createBlacklistItem();
      });

      Promise.all(pendingCreates).then((newBlacklistItems: MediaTrackerSiteFilter[]) => {
        setNewBlacklistItem({ ...newBlacklistItem, value: '' });

        const sortedNewBlacklistItems = newBlacklistItems.sort((a: MediaTrackerSiteFilter, b: MediaTrackerSiteFilter) =>
          a.id < b.id ? -1 : 1
        );

        mutate(
          PATHS.MEDIA_TRACKER_SITE_FILTERS(siteId),
          () => ({
            media_tracker_site_filters: [...(data?.media_tracker_site_filters || []), ...sortedNewBlacklistItems],
          }),
          { revalidate: false }
        );

        setIsCreating(false);
      });
    } catch (e) {
      if (e instanceof BlacklistError) {
        alert(e.message);
        setIsCreating(false);
        return;
      }

      throw e;
    }
  };

  const deleteItem = (id: number) => {
    const item = orderedBlacklistItems.find((item: MediaTrackerSiteFilter) => item.id === id);
    if (!item) return;

    mutate(
      PATHS.MEDIA_TRACKER_SITE_FILTERS(siteId),
      async () => {
        const response = await fetch(
          `${config.flocklerPrivateApiUrl}/sites/${siteId}/media_tracker_site_filters/${item.id}`,
          {
            method: 'DELETE',
            credentials: 'include',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-CSRF-TOKEN': CsrfToken.get(),
            },
          }
        );

        if (response.status === 204) {
          return {
            media_tracker_site_filters: (data?.media_tracker_site_filters || []).filter(
              (item: MediaTrackerSiteFilter) => item.id !== id
            ),
          };
        }

        alert('Blacklist filter could not be deleted');
        return data;
      },
      {
        optimisticData: {
          media_tracker_site_filters: (data?.media_tracker_site_filters || []).filter(
            (item: MediaTrackerSiteFilter) => item.id !== id
          ),
        },
        revalidate: false,
      }
    );
  };

  return (
    <div className="mx-auto max-w-md space-y-5 px-4 py-6 sm:max-w-3xl">
      <div>
        <Link to={pathToAutomatedFeeds(siteUrl)} className="text-sm font-semibold">
          <Icon type="angle-left" /> Back to Feeds list
        </Link>
      </div>
      <Heading level="h1" type="primary">
        Blacklist Users and Keywords
      </Heading>

      <div className="space-y-3 rounded bg-slate-50 p-5 text-sm">
        <form onSubmit={addItem}>
          <div className="flex flex-col gap-2 sm:flex sm:flex-row sm:items-center">
            <div className="sm:w-32 sm:shrink-0">
              <select
                className="input--small"
                aria-label="Channel"
                value={newBlacklistItem.channel}
                disabled={isCreating}
                onChange={(e) =>
                  setNewBlacklistItem({ ...newBlacklistItem, channel: e.target.value as BlacklistItem['channel'] })
                }
              >
                <option value="facebook">Facebook</option>
                <option value="instagram_graph_api">Instagram</option>
                <option value="twitter">X</option>
              </select>
            </div>

            <div className="sm:w-32 sm:shrink-0">
              <select
                className="input--small"
                aria-label="Type"
                value={newBlacklistItem.type}
                disabled={isCreating}
                onChange={(e) =>
                  setNewBlacklistItem({ ...newBlacklistItem, type: e.target.value as BlacklistItem['type'] })
                }
              >
                <option value="exclude_author">Username</option>
                <option value="exclude_keyword">Keyword</option>
              </select>
            </div>
            <div className="sm:grow">
              <input
                className="input--small"
                aria-label="Value"
                type="text"
                placeholder="Enter usernames or keywords…"
                value={newBlacklistItem.value}
                disabled={isCreating}
                maxLength={255}
                onChange={(e) => setNewBlacklistItem({ ...newBlacklistItem, value: e.target.value })}
              />
            </div>
            <div>
              <Button type="submit" variant="danger" size="small" disabled={isCreating}>
                Block
              </Button>
            </div>
          </div>
        </form>
        <HelpText>
          Note that the blacklist will only block{' '}
          <strong className="text-medium rounded bg-pink-100 px-1">future content</strong> mentioning the keyword or
          posts by a user.
        </HelpText>
      </div>

      {orderedBlacklistItems.length ? (
        <table className="w-full table-fixed text-left">
          <thead className="text-sm">
            <tr>
              <th className="block border-b-2 py-2 px-1 font-semibold sm:table-cell">
                Blacklisted username or keyword
              </th>
              <th className="w-0 border-b-2  py-2 px-1 font-semibold sm:w-28">
                <span className="hidden sm:inline">Type</span>
              </th>
              <th className="w-0 border-b-2  py-2 px-1 font-semibold sm:w-28">
                <span className="hidden sm:inline">Channel</span>
              </th>
              <th className="w-8  border-b-2"></th>
            </tr>
          </thead>
          <tbody className="text-base">
            {orderedBlacklistItems.map((blacklistFilter: MediaTrackerSiteFilter) => (
              <tr key={blacklistFilter.id}>
                <td className="border-b py-2 px-1 align-middle text-sm sm:text-base">
                  {blacklistFilter.target_service !== 'facebook' && blacklistFilter.filter_type === 'exclude_author'
                    ? `@${blacklistFilter.parameters[0].value}`
                    : blacklistFilter.parameters[0].value}

                  <div className="flex items-center space-x-1 text-xxs font-medium uppercase tracking-wider text-slate-400 sm:hidden">
                    {blacklistFilter.target_service === 'facebook' && <Icon type="facebook" />}
                    {blacklistFilter.target_service === 'instagram_graph_api' && <Icon type="instagram" />}
                    {blacklistFilter.target_service === 'twitter' && <Icon type="twitter" />}
                    <span>
                      {blacklistFilter.filter_type === 'exclude_author' && 'Username'}
                      {blacklistFilter.filter_type === 'exclude_keyword' && 'Keyword'}
                    </span>
                  </div>
                </td>
                <td className="border-b py-2 px-1 align-middle text-xs font-medium uppercase tracking-wider text-slate-500">
                  <span className="hidden sm:inline">
                    {blacklistFilter.filter_type === 'exclude_author' && 'Username'}
                    {blacklistFilter.filter_type === 'exclude_keyword' && 'Keyword'}
                  </span>
                </td>
                <td className="border-b py-2 px-1 align-middle text-xs font-medium uppercase tracking-wider text-slate-500">
                  <span className="hidden sm:inline">
                    {blacklistFilter.target_service === 'facebook' && 'Facebook'}
                    {blacklistFilter.target_service === 'instagram_graph_api' && 'Instagram'}
                    {blacklistFilter.target_service === 'twitter' && 'Twitter'}
                  </span>
                </td>
                <td className="border-b py-2 px-1 text-center align-middle ">
                  <button
                    className="text-sm font-semibold text-slate-400 hover:text-red-700"
                    type="button"
                    aria-label="Delete item"
                    onClick={() => deleteItem(blacklistFilter.id)}
                  >
                    <Icon type="remove-circle" className="scale-105 transform" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="text-center text-slate-500">
          {!data ? (
            <LoadingIndicator />
          ) : (
            <div className="rounded border border-dashed border-slate-300 p-10 text-center text-slate-500">
              No blacklisted keywords or users yet. 👌
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Blacklist;
