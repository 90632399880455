import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import highlightStyle from 'react-syntax-highlighter/dist/esm/styles/prism/coy';

import { getCurrentSite, getCurrentSiteId, getSitesLoadingStatus } from 'concepts/site';
import { fetchEmbeds, getEmbeds, getEmbedsLoadingState } from 'concepts/embed';
import { pathToAutomatedFeeds, pathToDisplay, pathToNewDisplay } from 'services/routes';

import Heading from 'components/Heading';
import Button from 'components/Button';
import LoadingIndicator from 'components/Loader/LoadingIndicator';
import Page from 'components/Page';
import Icon from 'components/Icon';
import Timeout from 'components/Timeout';
import config from 'config';
import AngleLink from 'components/AngleLink';
import CardLink from 'components/CardLink';
import NoticeBox from 'components/NoticeBox';

type ResponsiveEmbedProps = {
  site: Site;
  currentSiteId?: SiteId;
  isLoadingSite: boolean;
  isLoadingEmbeds: boolean;
  embeds: Embed[];
  fetchEmbeds: () => void;
};

const ResponsiveEmbed = ({
  site,
  currentSiteId,
  fetchEmbeds,
  isLoadingSite,
  isLoadingEmbeds,
  embeds: emb,
}: ResponsiveEmbedProps) => {
  useEffect(() => {
    if (currentSiteId) {
      fetchEmbeds();
    }
  }, [currentSiteId, fetchEmbeds]);
  const isLoadingView = !currentSiteId || isLoadingSite || isLoadingEmbeds || site == null;

  const DEFAULT_BREAKPOINT = 450;

  const [desktop, setDesktop] = useState<string>('');
  const [mobile, setMobile] = useState<string>('');
  const [customize, setCustomize] = useState(false);
  const [breakpoint, setBreakpoint] = useState<number | null>(DEFAULT_BREAKPOINT);
  const [generatedCode, setGeneratedCode] = useState<string>('');

  // Reverse to get new-old ordering
  const embeds = useMemo(() => [...emb].reverse(), [emb]);

  const canGenerate = !!desktop && !!mobile && desktop !== mobile;

  const displayOptions = embeds.map((display) => (
    <option key={display.uuid} value={display.uuid}>
      {display.name}
    </option>
  ));

  const onChangeBreakpoint = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value;
    const nval = val === '' ? null : Number(val);

    if (nval !== null && isNaN(nval)) return;
    if (!nval || (nval >= 0 && nval < 10000)) setBreakpoint(nval);
  };

  const generateCode = () =>
    canGenerate &&
    setGeneratedCode(`<div id="flockler-embed-${mobile}" class="flockler-embed--mobile"></div>
<div id="flockler-embed-${desktop}" class="flockler-embed--desktop"></div>

<script class="flockler-custom-embed">
  (function () {
    const BREAKPOINT_SCREEN_WIDTH = ${breakpoint ?? DEFAULT_BREAKPOINT};
    const SITE_UUID = '${site.uuid}';
    const MOBILE_EMBED_UUID = '${mobile}';
    const DESKTOP_EMBED_UUID = '${desktop}';

    const embedUuid = window.innerWidth >= BREAKPOINT_SCREEN_WIDTH ? DESKTOP_EMBED_UUID : MOBILE_EMBED_UUID;
    const wrapper = document.querySelector(\`#flockler-embed-\${embedUuid}\`);
    const scriptSrc = \`${config.flocklerPluginsUrl}/embed/\${SITE_UUID}/\${embedUuid}\`

    const script = document.createElement('script');
    script.async = true;
    script.src = scriptSrc;
    wrapper.parentElement.appendChild(script);
  })();
</script>`);

  const reset = () => {
    setGeneratedCode('');
    setDesktop('');
    setMobile('');
  };

  const hasSelectedSameEmbed = desktop === mobile && desktop !== '';

  return (
    <Page>
      <Helmet>
        <title>Flockler {'\u203A'} Two Embeds</title>
      </Helmet>
      <Heading level="h1" type="primary">
        {!generatedCode ? (
          <>Switch layouts between mobile&nbsp;and&nbsp;desktop</>
        ) : (
          'Great job! Next, add the embed code to your website!'
        )}
      </Heading>

      <div className="space-y-5 md:text-lg">
        {!generatedCode ? (
          <div className="mx-auto max-w-3xl space-y-8">
            <h2 className="text-center text-lg font-bold md:text-xl">
              Would you like to display a different layout on desktop and mobile?
            </h2>
            <div className="mx-auto max-w-2xl space-y-5">
              <p>
                This simple tool will help you create a custom embed code for any website showing a different layout on
                desktop and mobile. For example, you might want to show a Grid layout on the desktop and a Carousel on
                the mobile view.
              </p>
              {isLoadingView ? (
                <div className="flex h-20 items-center justify-center">
                  <LoadingIndicator />
                </div>
              ) : embeds.length < 2 ? (
                <NoticeBox type="warning" withIcon className="text-lg">
                  Make sure you’ve created at least two layouts on the{' '}
                  <Link to={isLoadingSite || !site?.site_url ? '/' : pathToDisplay(site.site_url)}>Display tab</Link>{' '}
                  before getting started.
                </NoticeBox>
              ) : (
                <div className="space-y-5">
                  <div className="grid gap-4 xs:grid-cols-2">
                    <div className="space-y-1">
                      <label htmlFor="responsiveEmbedDesktop" className="flex items-center justify-start space-x-2">
                        <span className="h-5 w-5 text-center">
                          <Icon type="computer-desktop" className="h-full w-full text-brand" />
                        </span>
                        <span className="ml-1">Show on Desktop</span>
                      </label>
                      <select
                        id="responsiveEmbedDesktop"
                        value={desktop ?? ''}
                        onChange={(e) => setDesktop(e.currentTarget.value)}
                        className={desktop === '' ? 'text-slate-500' : ''}
                      >
                        <option value="" disabled>
                          Select a display…
                        </option>
                        {displayOptions}
                      </select>
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="responsiveEmbedMobile" className="flex items-center justify-start space-x-2">
                        <span className="h-5 w-5 text-center">
                          <Icon type="device-phone-mobile" className="h-full w-full text-brand" />
                        </span>
                        <span className="ml-1">Show on Mobile</span>
                      </label>
                      <select
                        id="responsiveEmbedMobile"
                        value={mobile ?? ''}
                        onChange={(e) => setMobile(e.currentTarget.value)}
                        className={mobile === '' ? 'text-slate-500' : ''}
                      >
                        <option value="" disabled>
                          Select a display…
                        </option>
                        {displayOptions}
                      </select>
                    </div>

                    {hasSelectedSameEmbed && (
                      <div className="xs:col-span-2">
                        <NoticeBox type="warning" withIcon>
                          Please choose two different layouts; one to show on desktop and one on mobile
                        </NoticeBox>
                      </div>
                    )}

                    <div className="xs:col-span-2">
                      {customize ? (
                        <div className="flex items-start gap-2 xs:items-center">
                          <Icon type="arrows-right-left" className="h-5 w-5 shrink-0 text-brand" />
                          <div
                            className="
                          flex flex-row flex-wrap items-center gap-2 border-brand text-sm font-semibold text-slate-600 transition-opacity"
                          >
                            <span>Switch to Mobile layout when screen is less than</span>

                            <div className="inline-flex flex-shrink-0 items-center gap-2">
                              <div className="relative flex-grow-0">
                                {customize && (
                                  <input
                                    type="text"
                                    id="breakpoint"
                                    value={breakpoint ?? ''}
                                    onChange={onChangeBreakpoint}
                                    className="!h-9 !w-20 !pr-8 text-right"
                                    placeholder={String(DEFAULT_BREAKPOINT)}
                                  />
                                )}
                                <span className="absolute bottom-2 right-4 select-none font-semibold text-slate-400">
                                  px
                                </span>
                              </div>
                              <span>in width.</span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="flex flex-row items-center gap-1 text-slate-600 opacity-60 transition-opacity hover:opacity-100"
                          style={{ minHeight: '2.25rem' }}
                        >
                          <Icon type="info-circle" className="h-6 w-6 text-brand" />
                          <p className="text-sm font-medium">
                            The default breakpoint is {DEFAULT_BREAKPOINT} px. You can optionally{' '}
                            <button
                              onClick={() => setCustomize(!customize)}
                              className="font-semibold text-brand hover:underline"
                            >
                              customize the breakpoint...
                            </button>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>

                  <Button
                    onClick={() => generateCode()}
                    className="w-fit transition-all"
                    size="large"
                    disabled={!canGenerate}
                  >
                    Generate embed code
                  </Button>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="mx-auto max-w-3xl space-y-8">
            <div className="mx-auto max-w-md text-center text-lg">
              <p>You can embed the code below on any website and it shows a different layout on desktop and mobile.</p>
            </div>

            <div className="relative overflow-hidden rounded-lg border border-slate-100 bg-slate-50 text-sm">
              <div
                className="h-16 p-3"
                onClick={(e) => {
                  const range = document.createRange();
                  range.selectNode(e.currentTarget);
                  window.getSelection()?.removeAllRanges();
                  window.getSelection()?.addRange(range);
                }}
              >
                <SyntaxHighlighter
                  language="jsx"
                  style={highlightStyle}
                  PreTag={({ children }: { children: React.ReactNode }) => <pre>{children}</pre>}
                  codeTagProps={{ className: 'overflow-hidden' }}
                >
                  {generatedCode}
                </SyntaxHighlighter>
              </div>
              <div className="absolute top-0 right-0 bottom-0 z-10 flex w-2/3 items-center justify-end bg-gradient-to-l from-slate-50 via-slate-50 pr-2">
                <Timeout
                  duration={3000}
                  element={(copied, triggerCopied) => (
                    <Button
                      onClick={() => {
                        navigator.clipboard.writeText(generatedCode);
                        triggerCopied();
                      }}
                      className={classNames(
                        'w-fit transition-all',
                        copied
                          ? '!bg-gradient-to-b !from-green-600 !to-green-700 !ring-4 !ring-green-600 !ring-offset-2 !transition-all'
                          : 'bg-none'
                      )}
                      size="large"
                      disabled={!canGenerate}
                    >
                      {copied ? 'Copied!' : 'Copy embed code'}
                    </Button>
                  )}
                />
              </div>
            </div>
            <div className="text-center">
              <button onClick={reset} className="space-x-1 font-medium text-brand">
                <AngleLink>Generate another responsive embed</AngleLink>
              </button>
            </div>
          </div>
        )}
      </div>

      {!!generatedCode && (
        <section className="mt-16">
          <Heading level="h2" type="primary">
            Try these next
          </Heading>
          <div className="flex flex-col md:flex-row">
            <CardLink
              to={pathToNewDisplay(site.site_url)}
              iconType="code-circle"
              text="Create a display layout"
              description="Unlimited layouts for your content."
            />

            <CardLink
              to={pathToAutomatedFeeds(site.site_url)}
              iconType="robot-circle"
              text="Create a new Automated Feed"
              description="Automated Feeds collect social posts for you to display."
            />
          </div>
        </section>
      )}
    </Page>
  );
};

const mapStateToProps = (state: RootState) => ({
  site: getCurrentSite(state),
  currentSiteId: getCurrentSiteId(state),
  isLoadingSite: getSitesLoadingStatus(state),
  isLoadingEmbeds: getEmbedsLoadingState(state),
  embeds: getEmbeds(state),
});

const mapDispatchToProps = { fetchEmbeds };

export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveEmbed);
