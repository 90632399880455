import { connect } from 'react-redux';

import { Route, Redirect, Switch, useRouteMatch, useLocation, matchPath } from 'react-router-dom';
import { getSiteUrl, getCurrentSite, getSiteSettings } from 'concepts/site';
import { getSubscription, Subscription, isExpired } from 'concepts/subscription';
import { getUserProfile, isAdminUser, getUserSiteRoles } from 'concepts/user';
import { RootState } from 'redux/store';

import { canRoleAccessPath } from 'services/user-role';

import AdminToolbar from 'components/AdminToolbar';
import Header from 'components/Header';
import ScrollToTop from 'components/ScrollToTop';
import FooterNotice from 'components/FooterNotice';
import SubscriptionExpiredNotice from 'components/SubscriptionExpiredNotice';
import ImpersonationWarning from 'components/ImpersonationWarning';
import LogoLoader from 'components/Loader/LogoLoader';
import PushNotifications from 'components/PushNotifications';
import PrivateRoute from 'components/PrivateRoute';

import { PATHS } from 'services/routes';

import Account from 'pages/account';
import Blacklist from 'pages/automated-feeds/blacklist';
import Dashboard from 'pages/dashboard';
import Display from 'pages/display';
import NewDisplay from 'pages/display/new-display';
import ResponsiveEmbed from 'pages/display/responsive-embed';
import ChooseSection from 'pages/display/choose-section';
import Embed from 'pages/display/embed';
import NewEmbed from 'pages/display/new-embed';
import Slideshow from 'pages/display/slideshow';
import NewSlideshow from 'pages/display/new-slideshow';
import EmbedScreen from 'pages/display/embed-screen';
import NewEmbedScreen from 'pages/display/new-embed-screen';
import NewsletterLayout from 'pages/display/newsletter/layout';
import NewsletterContent from 'pages/display/newsletter/content';
import AutomatedFeeds from 'pages/automated-feeds';

import Settings from 'pages/settings';
import Referral from 'pages/referral';
import ReferersList from 'pages/referers-list';

import Reviews from 'pages/reviews';
import ReviewFormEdit from 'pages/reviews/edit';
import ReviewFormView from 'pages/reviews/view';
import ReviewFormNew from 'pages/reviews/new';
import ReviewsExportContacts from 'pages/reviews/export-contacts';

import SiteApiKeys from 'pages/settings/components/SiteApiKeys';
import SiteSettings from 'pages/settings/components/SiteSettings';
import SiteUsers from 'pages/settings/components/SiteUsers';
import SiteSeo from 'pages/settings/components/SiteSeo';
import SubscriptionPlanSelect from 'pages/settings/components/SubscriptionPlanSelect';
import SubscriptionManageFeeds from 'pages/settings/components/SubscriptionManageFeeds';
import SubscriptionDetails from 'pages/settings/components/SubscriptionDetails';
import SubscriptionDetailsWithBankTransfer from 'pages/settings/components/SubscriptionDetailsWithBankTransfer';
import SubscriptionSuccess from 'pages/settings/components/SubscriptionSuccess';
import SubscriptionOverview from 'pages/settings/components/SubscriptionOverview';

import NotFound from 'pages/not-found';
import Analytics from 'pages/analytics';
import Commerce from 'pages/commerce';

import Sections from 'pages/sections';

import PageLoader from 'components/Loader/PageLoader';

import styles from './AppWrapper.module.scss';
import { IntroModal } from 'components/IntroModal';
import CreateSite from 'pages/create-site';
import RightsLandingPage from 'pages/rights';
import Feedback from 'pages/feedback';
import ChooseEmbedType from 'pages/display/choose-embed-type';

import MetricsNavigation from 'pages/metrics/components/MetricsNavigation';
import MetricsOverviewPage from 'pages/metrics/pages/overview';
import MetricsDetailsPage from 'pages/metrics/pages/details';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import useFirstTimeUserExperience from 'hooks/useFirstTimeUserExperience';
import AnalyticsNavigation from 'pages/analytics/components/AnalyticsNavigation';
import CrossAppAlert from 'components/CrossAppAlert';
import useCrossAppAlert from 'hooks/useCrossAppAlert';
import classNames from 'classnames';

type AppRoutesProps = {
  settings?: SiteSettings;
  site?: Site;
  userRoles?: UserRole[];
};

const dashboardRoles: UserRoleType[] = ['admin', 'owner', 'manager'];

const AppRoutes = ({ userRoles, site }: AppRoutesProps) => {
  const { url } = useRouteMatch();
  const currentRoleType = userRoles ? userRoles[0]?.role_name : null;

  if (!currentRoleType) {
    return <PageLoader />;
  }

  return (
    <>
      <ScrollToTop />
      <Switch>
        <Route exact path={`${url}`}>
          {site && site.site_type === 'embed' && dashboardRoles.includes(currentRoleType) ? (
            <Route exact path={`${url}`} component={Dashboard} />
          ) : site &&
            ((!!site.site_type && site.site_type !== 'embed') || !dashboardRoles.includes(currentRoleType)) ? (
            <Redirect to={`${url.replace(/\/$/, '')}/sections`} />
          ) : (
            <PageLoader />
          )}
        </Route>

        <Route exact path={`${url}/account`} component={Account} />

        <PrivateRoute path={`${url}/layouts`} accessAllowed={canRoleAccessPath(currentRoleType, '/layouts')}>
          <Route exact path={`${url}/layouts`} component={Display} />
          <Route exact path={`${url}/layouts/responsive-embed`} component={ResponsiveEmbed} />
          <Route exact path={`${url}/layouts/new`} component={NewDisplay} />
          <Route exact path={`${url}/layouts/new/:displayType/type`} component={ChooseEmbedType} />
          <Route exact path={`${url}/layouts/new/:type/:displayType/section`} component={ChooseSection} />
          <Route exact path={`${url}/layouts/embed/:embedUuid`} component={Embed} />
          <Route exact path={`${url}/layouts/embed/:embedUuid/new`} component={NewEmbed} />
          <Route exact path={`${url}/layouts/slideshow/:slideshowUuid`} component={Slideshow} />
          <Route exact path={`${url}/layouts/slideshow/:slideshowUuid/new`} component={NewSlideshow} />
          <Route exact path={`${url}/layouts/screen/:embedUuid`} component={EmbedScreen} />
          <Route exact path={`${url}/layouts/screen/:embedUuid/new`} component={NewEmbedScreen} />

          <Route path={`${url}/layouts/newsletter`}>
            <Switch>
              <Route path={`${url}/layouts/newsletter/layout`} component={NewsletterLayout} />
              <Route path={`${url}/layouts/newsletter/content/:layout`} component={NewsletterContent} />

              <Redirect to={`${url}/layouts/newsletter/layout`} />
            </Switch>
          </Route>
        </PrivateRoute>

        <PrivateRoute exact path={`${url}/media-trackers`} accessAllowed={canRoleAccessPath(currentRoleType, '/feeds')}>
          <Redirect to={`${url}/feeds`} />
        </PrivateRoute>

        <PrivateRoute path={`${url}/feeds`} accessAllowed={canRoleAccessPath(currentRoleType, '/feeds')}>
          <Route exact path={`${url}/feeds`} component={AutomatedFeeds} />
          <Route exact path={`${url}/feeds/blacklist`} component={Blacklist} />
        </PrivateRoute>

        <PrivateRoute path={`${url}/settings`} accessAllowed={canRoleAccessPath(currentRoleType, '/settings')}>
          <Settings>
            <Switch>
              <Route exact path={`${url}/settings`}>
                <Redirect to={`${url}/settings/general`} />
              </Route>
              <Route exact path={`${url}/settings/api-keys`} component={SiteApiKeys} />
              <Route exact path={`${url}/settings/general`} component={SiteSettings} />
              <Route exact path={`${url}/settings/users`} component={SiteUsers} />
              <Route exact path={`${url}/settings/seo`} component={SiteSeo} />

              <PrivateRoute
                path={`${url}/settings/subscription`}
                accessAllowed={canRoleAccessPath(currentRoleType, '/settings/subscription')}
                isSubRoute
              >
                <Switch>
                  <Route exact path={`${url}/settings/subscription/choose-plan`} component={SubscriptionPlanSelect} />
                  <Route exact path={`${url}/settings/subscription/manage-feeds`} component={SubscriptionManageFeeds} />
                  <Route exact path={`${url}/settings/subscription/success`} component={SubscriptionSuccess} />
                  <Route
                    exact
                    path={`${url}/settings/subscription/buy/:paymentMethod/:plan`}
                    component={SubscriptionDetails}
                  />
                  <Route
                    exact
                    path={`${url}/settings/subscription/buy-with-bank-transfer/:plan`}
                    component={SubscriptionDetailsWithBankTransfer}
                  />
                  <Route path={`${url}/settings/subscription`} component={SubscriptionOverview} />
                </Switch>
              </PrivateRoute>

              <Route path={`${url}/settings`}>
                <Redirect to={`${url}/settings/general`} />
              </Route>
            </Switch>
          </Settings>
        </PrivateRoute>

        <PrivateRoute path={`${url}/analytics`} accessAllowed={canRoleAccessPath(currentRoleType, '/analytics')}>
          <AnalyticsNavigation />

          <Switch>
            <Route path={`${url}/analytics/metrics/:page?/:embedUuid?`}>
              <MetricsNavigation>
                <Switch>
                  <Route exact path={`${url}/analytics/metrics/overview/:embedUuid?`} component={MetricsOverviewPage} />
                  <Route exact path={`${url}/analytics/metrics/details/:embedUuid?`} component={MetricsDetailsPage} />
                  <Redirect to={`${url}/analytics/metrics/overview`} />
                </Switch>
              </MetricsNavigation>
            </Route>
            <Route exact path={`${url}/analytics/social`} component={Analytics} />
            <Redirect to={`${url}/analytics/metrics/overview`} />
          </Switch>
        </PrivateRoute>

        <PrivateRoute
          path={`${url}/commerce`}
          accessAllowed={site?.site_type === undefined || site?.site_type === 'embed'}
        >
          <Route exact path={`${url}/commerce`} component={Commerce} />
        </PrivateRoute>

        <PrivateRoute path={`${url}/sections`} accessAllowed={canRoleAccessPath(currentRoleType, '/sections')}>
          <Route exact path={`${url}/sections`} component={Sections} />
        </PrivateRoute>

        <Route exact path={`${url}/refer-a-friend`} component={Referral} />

        <Route exact path={`${url}/referers-list`} component={ReferersList} />

        <Route exact path={`${url}/feedback`} component={Feedback} />

        <PrivateRoute
          accessAllowed={canRoleAccessPath(currentRoleType, '/reviews')}
          exact
          path={`${url}/reviews`}
          component={Reviews}
        />
        <PrivateRoute
          accessAllowed={canRoleAccessPath(currentRoleType, '/reviews')}
          exact
          path={`${url}/reviews/export-contacts`}
          component={ReviewsExportContacts}
        />
        <PrivateRoute
          accessAllowed={canRoleAccessPath(currentRoleType, '/reviews')}
          exact
          path={`${url}/reviews/new`}
          component={ReviewFormNew}
        />
        <PrivateRoute
          accessAllowed={canRoleAccessPath(currentRoleType, '/reviews')}
          exact
          path={`${url}/reviews/:uuid/edit`}
          component={ReviewFormEdit}
        />
        <PrivateRoute
          accessAllowed={canRoleAccessPath(currentRoleType, '/reviews')}
          exact
          path={`${url}/reviews/:uuid`}
          component={ReviewFormView}
        />
        <PrivateRoute path={`${url}/rights`} accessAllowed={canRoleAccessPath(currentRoleType, '/rights')}>
          <Route exact path={`${url}/rights`} component={RightsLandingPage} />
        </PrivateRoute>

        <Route component={NotFound} />
      </Switch>
    </>
  );
};

const AppLoader = () => (
  <div className={styles.appLoader}>
    <LogoLoader />
  </div>
);

type AppWrapperProps = {
  siteUrl: string;
  site: Site;
  subscription: Subscription;
  user?: User;
  userSiteRoles?: UserRole[];
  isAdmin: boolean;
  settings?: SiteSettings;
};

const getIsSettingsPath = (currentPath: string) => {
  return matchPath(currentPath, { path: PATHS.SETTINGS_SUBSCRIPTION_OVERVIEW });
};

const AppWrapper = ({ siteUrl, site, subscription, user, userSiteRoles, isAdmin, settings }: AppWrapperProps) => {
  const { pathname } = useLocation();
  const isSettingsPath = getIsSettingsPath(pathname);
  const isFirstTimeUserExperience = useFirstTimeUserExperience();
  const { currentAlert, dismissAlert } = useCrossAppAlert();

  // Redirect to root when user has been resolved and they are not an admin
  if (user && pathname === '/create-site' && !isAdmin) {
    window.location.href = '/';
    return <AppLoader />;
  }

  return (
    <div className={styles.appWrapper}>
      {!isFirstTimeUserExperience && (
        <div className={classNames(styles.appWrapperHeader, !!currentAlert && styles.appWrapperHeaderWithAlertShown)}>
          <Header />
        </div>
      )}

      <div className={styles.appWrapperMain}>
        <Switch>
          <Route path="/create-site">{user ? <CreateSite /> : <AppLoader />}</Route>

          <Route exact path="/">
            {siteUrl ? <Redirect to={`/${siteUrl}`} /> : <AppLoader />}
          </Route>
          <Route
            path="/:siteUrl"
            render={() => <AppRoutes userRoles={userSiteRoles} settings={settings} site={site} />}
          />
        </Switch>
      </div>
      {site && subscription?.valid_until && !isFirstTimeUserExperience && (
        <div className={styles.appWrapperFooter}>
          <FooterNotice />
        </div>
      )}

      <PushNotifications />

      {!isFirstTimeUserExperience && <IntroModal />}

      {subscription && isExpired(subscription) && !isSettingsPath && <SubscriptionExpiredNotice />}

      {user?.is_being_impersonated_by_admin && <ImpersonationWarning />}

      {isAdmin && <AdminToolbar site={site} subscription={subscription} settings={settings} />}

      {!!currentAlert && <CrossAppAlert alert={currentAlert} onDismiss={dismissAlert} />}

      <Tooltip id="tooltip" style={{ zIndex: 9999 }} />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  siteUrl: getSiteUrl(state),
  site: getCurrentSite(state),
  user: getUserProfile(state),
  userSiteRoles: getUserSiteRoles(state),
  subscription: getSubscription(state),
  isAdmin: isAdminUser(state),
  settings: getSiteSettings(state),
});

export default connect(mapStateToProps)(AppWrapper);
