import React from 'react';
import classnames from 'classnames';

import getEditPreviewUrl from 'services/slideshow-edit-preview-url';
import ScreenPreviewIframe from '../ScreenPreviewIframe';
import Icon from 'components/Icon';
import SaveButton from 'components/SaveButton';
import useScrollWatcher from 'hooks/useScrollWatcher';

import styles from './ScreenPreview.module.scss';
import useSiteUuid from 'hooks/useSiteUuid';
import getEmbedScreenEditPreviewUrl from 'services/embed-screen-edit-preview-url';

interface ScreenPreviewProps {
  editValues: any;
  editVersion: number;
  display: Slideshow | EmbedScreen;
  displayUpdateError: any;
  hasUnsavedChanges: boolean;
  isSaving: boolean;
  isScreen?: boolean;
  onSave: () => void;
}

function ScreenPreview({
  editValues,
  editVersion,
  display,
  displayUpdateError,
  hasUnsavedChanges,
  isSaving,
  isScreen,
  onSave,
}: ScreenPreviewProps) {
  const previewEl = React.useRef<HTMLDivElement>(null);
  const isPreviewVisible = useScrollWatcher(previewEl, 300);
  const isPipMode = !isPreviewVisible;

  const siteUuid = useSiteUuid();

  const editPreviewUrl = isScreen
    ? getEmbedScreenEditPreviewUrl({
        configuration: editValues,
        embedScreenUuid: display.uuid,
        siteUuid,
        editVersion,
      })
    : getEditPreviewUrl({
        configuration: editValues,
        slideshowUuid: display.uuid,
        siteUuid,
        editVersion,
      });

  return (
    <div ref={previewEl} className={classnames(styles.preview, { [styles.pipMode]: isPipMode })}>
      <div className={styles.previewArea}>
        <ScreenPreviewIframe isPipMode={isPipMode} url={editPreviewUrl} isEmbedScreen={!!isScreen} />

        <div className={styles.previewFooter}>
          <SaveButton
            className={styles.previewSaveButton}
            type="button"
            size="small"
            isSaving={isSaving}
            hasError={!!displayUpdateError}
            action={onSave}
            style={{ minWidth: '7.5rem' }}
          >
            {isSaving ? 'Saving' : 'Save changes'}
          </SaveButton>

          {hasUnsavedChanges && <span className={styles.unsavedChanges}>Unsaved changes</span>}

          <a
            href={display.shortcut_url}
            target="_blank"
            rel="noopener noreferrer"
            className={classnames(styles.previewLink, { [styles.disabled]: hasUnsavedChanges })}
          >
            View <Icon className={styles.previewLinkIcon} type="external-link" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default ScreenPreview;
