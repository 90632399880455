import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import { RootState } from 'redux/store';
import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import jsx from 'react-syntax-highlighter/dist/esm/languages/prism/jsx';
import highlightStyle from 'react-syntax-highlighter/dist/esm/styles/prism/coy';

import { getCurrentSiteState } from 'concepts/site';
import { fetchReviewsServiceDetails, getReviewsServiceDetailsState } from 'concepts/reviews-service';
import { pathToAutomatedFeeds, pathToReviews, pathToNewDisplay, pathToReviewsEditForm } from 'services/routes';
import { formatEmbedCodeMailtoLink } from 'services/display-mailto-link';
import { useReviewForm } from '../hooks/useReviewForms';
import config from 'config';

import AngleLink from 'components/AngleLink';
import CardLink from 'components/CardLink';
import Heading from 'components/Heading';
import Page from 'components/Page';
import PageLoader from 'components/Loader/PageLoader';
import Button from 'components/Button';
import Icon from 'components/Icon';
import ReviewFormIframePreview from '../components/ReviewFormIframePreview';

SyntaxHighlighter.registerLanguage('jsx', jsx);

const ReviewFormView = ({
  site,
  reviewsServiceDetails,
}: {
  site: Site;
  reviewsServiceDetails: ReviewsServiceDetails;
}) => {
  const { uuid } = useParams() as { uuid: string };
  const dispatch = useDispatch();
  const [isCopiedMessageVisible, setCopiedMessageVisible] = useState(false);

  useEffect(() => {
    if (!reviewsServiceDetails && site) {
      dispatch(fetchReviewsServiceDetails(site.id));
    }
  }, [dispatch, site, reviewsServiceDetails]);

  const { data, isLoading } = useReviewForm({
    site,
    reviewFormUuid: uuid,
    reviewsServiceDetails,
  });

  const [form, setForm] = useState<ReviewForm | undefined>();

  useEffect(() => {
    if (!form && data) setForm(data.reviewForm);
  }, [form, data]);

  if (!reviewsServiceDetails || isLoading) {
    return (
      <Page>
        <Helmet>
          <title>
            Flockler {'\u203A'} Reviews {'\u203A'} Loading…
          </title>
        </Helmet>
        <PageLoader />
      </Page>
    );
  }

  if (!form) {
    return (
      <Page>
        <Helmet>
          <title>
            Flockler {'\u203A'} Reviews {'\u203A'} Loading…
          </title>
        </Helmet>
        <Heading level="h1" type="primary">
          Review Form not found
        </Heading>
        <div className="space-y-5 text-center">
          <p>
            The review form with ID{' '}
            <code className="inline-block rounded border border-slate-200 bg-slate-50 py-1 px-2 text-sm">{uuid}</code>{' '}
            could not be found.
          </p>
          <p>
            <Link to={pathToReviews(site.site_url)}>Go back to reviews</Link>
          </p>
        </div>
      </Page>
    );
  }

  const embedCode = `
<div class="flockler-review-form" data-site-uuid="${site.uuid}" data-form-uuid="${uuid}"></div>
<script src="${config.flocklerPluginsUrl}/reviews/embed.js" async></script>
`.trim();

  return (
    <Page className="!max-w-6xl">
      <Helmet>
        <title>
          Flockler {'\u203A'} Reviews {'\u203A'} {form.name}
        </title>
      </Helmet>
      <Heading level="h1" type="primary">
        Great job! Next, add the form to your website!
      </Heading>
      <div className="relative pb-10 md:flex md:pb-0">
        <div className="md:w-3/5 md:pr-6">
          <div className="text-lg">
            <p>
              You can embed the code below on any website and it shows your form. Once you receive reviews, you can find
              them via Content tab.
            </p>
          </div>
          <div className="mt-10">
            <div className="relative overflow-hidden rounded-lg border border-slate-100 bg-slate-50 text-sm">
              <div
                className="p-3"
                onClick={(e) => {
                  const range = document.createRange();
                  range.selectNode(e.currentTarget);
                  window.getSelection()?.removeAllRanges();
                  window.getSelection()?.addRange(range);
                }}
              >
                <SyntaxHighlighter
                  language="jsx"
                  style={highlightStyle}
                  PreTag={({ children }: { children: React.ReactNode }) => <pre>{children}</pre>}
                  codeTagProps={{ className: 'overflow-hidden' }}
                >
                  {embedCode}
                </SyntaxHighlighter>
              </div>
              <div className="absolute top-0 right-0 bottom-0 z-10 flex w-2/5 items-center justify-end bg-gradient-to-l from-slate-50 via-slate-50 pr-2">
                <Button
                  variant="primary"
                  action={() => {
                    setCopiedMessageVisible(true);
                    navigator.clipboard.writeText(embedCode).then(
                      function () {
                        setTimeout(() => {
                          setCopiedMessageVisible(false);
                        }, 1500);
                      },
                      function () {
                        setCopiedMessageVisible(false);
                      }
                    );
                  }}
                  className="w-48"
                >
                  {isCopiedMessageVisible ? 'Copied!' : 'Copy embed code'}
                </Button>
              </div>
            </div>
          </div>

          <div className="mt-4 flex flex-col md:flex-row md:gap-8">
            <Link
              to={pathToReviewsEditForm(site.site_url, form.uuid as string)}
              className="flex items-center py-2 text-sm font-semibold text-brand"
            >
              <Icon type="customize" className="mr-2 h-5 w-5" />
              Edit style and settings
            </Link>
            <a
              className="flex items-center py-2 text-sm font-semibold text-brand"
              target="_blank"
              rel="noopener noreferrer"
              href={`${reviewsServiceDetails.apiBaseUrl}/${form.siteUuid}/forms/${form.uuid}/preview`}
            >
              <Icon type="eye-circle" className="mr-2 h-5 w-5" />
              Preview
            </a>
            <a
              className="flex items-center py-2 text-sm font-semibold text-brand"
              target="_blank"
              rel="noopener noreferrer"
              href={formatEmbedCodeMailtoLink('Review Form', embedCode)}
            >
              <Icon type="email-circle" className="mr-2 h-5 w-5" />
              Send via email
            </a>
          </div>

          <div className="absolute bottom-0 md:relative md:mt-12">
            <Link to={pathToReviews(site.site_url)} className="space-x-1 font-medium text-brand">
              <AngleLink>View all Review Forms</AngleLink>
            </Link>
          </div>
        </div>
        <div className="mt-5 md:mt-0 md:w-2/5">
          <ReviewFormIframePreview apiBaseUrl={reviewsServiceDetails.apiBaseUrl} form={form} />
        </div>
      </div>

      <section className="mt-10">
        <Heading level="h2" type="primary">
          Try these next
        </Heading>
        <div className="flex flex-col md:flex-row">
          <CardLink
            to={pathToNewDisplay(site.site_url)}
            iconType="code-circle"
            text="Create a display layout"
            description="Unlimited layouts for you to show reviews."
          />

          <CardLink
            to={pathToAutomatedFeeds(site.site_url)}
            iconType="robot-circle"
            text="Create a new Automated Feed"
            description="Automated Feeds collect social posts for you to display."
          />
        </div>
      </section>
    </Page>
  );
};

const mapStateToProps = (state: RootState) => ({
  site: getCurrentSiteState(state),
  reviewsServiceDetails: getReviewsServiceDetailsState(state),
});

const mapDispatchToProps = {
  fetchReviewsServiceDetails,
};

ReviewFormView.displayName = 'ReviewFormView';
export default connect(mapStateToProps, mapDispatchToProps)(ReviewFormView);
