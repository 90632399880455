import React from 'react';

import Button from 'components/Button';
import Modal from 'components/Modal';

type UnsavedChangesModalProps = {
  isVisible: boolean;
  isSaving: boolean;
  isValid: boolean;
  onClose: any;
  onConfirm: any;
  onSave?: any;
};

const UnsavedChangesModal = ({
  isSaving,
  isVisible,
  isValid,
  onClose,
  onConfirm,
  onSave,
}: UnsavedChangesModalProps) => {
  if (!isVisible) {
    return null;
  }

  return (
    <Modal
      title="You have unsaved changes. Are you sure?"
      actionButtons={[
        <Button key="cancelUnsavedChanges" variant="neutral" size="small" action={onClose}>
          Cancel
        </Button>,

        <div>
          {isValid && (
            <Button disabled={isSaving} key="saveUnsavedChanges" variant="success" size="small" action={onSave}>
              {isSaving ? 'Saving…' : 'Save and close'}
            </Button>
          )}
          <Button key="dismissUnsavedChanges" variant="danger" size="small" action={onConfirm}>
            Close
          </Button>
        </div>,
      ]}
      dismissAction={onClose}
    >
      <>You will lose all unsaved changes.</>
    </Modal>
  );
};

export default UnsavedChangesModal;
