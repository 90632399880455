import classnames from 'classnames';
import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import { useEffect, useState, createRef, useMemo } from 'react';
import config from 'config';
import debounce from 'lodash/debounce';
import useSiteUrl from 'hooks/useSiteUrl';
import Input from '../Input';
import Select from '../Select';
import { getProductFeeds, fetchProductFeeds } from 'concepts/product-feed';
import Button from 'components/Button';
import styles from './ProductSearch.module.scss';
import LoadingIndicator from 'components/Loader/LoadingIndicator';
import { useOnClickOutside } from 'usehooks-ts';

type ProductSearchProps = {
  fetchProductFeeds: (siteId?: number | undefined) => Promise<any>;
  handleChange: (product: { feedId: string; productId: string } | undefined) => void;
  id: string;
  name: string;
  productFeeds: ProductFeed[] | undefined;
  value: { feedId: string | undefined; productId: string | undefined };
};

type Product = {
  id: string;
  name: string;
  description: string;
  link: string;
  imageUrl: string;
  price: string;
};

type ProductSearchResponse = {
  results: Product[];
};

type ProductResultResponse = {
  products: Product[];
};

const ProductSearch = ({ fetchProductFeeds, id, name, productFeeds, handleChange, value }: ProductSearchProps) => {
  const productSearchAreaRef = createRef<HTMLDivElement>();
  const [inputValue, setInputValue] = useState<string>('');
  const [searchResults, setSearchResults] = useState<Product[] | undefined>(undefined);
  const [selectedProductFeedId, setSelectedProductFeedId] = useState<string | undefined>(undefined);
  const [isSearching, setIsSearching] = useState(false);
  const [isLoadingProductFeeds, setIsLoadingProductFeeds] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>(undefined);
  const siteUrl = useSiteUrl();

  // Fetch product feeds when component mounts
  useEffect(() => {
    if (!productFeeds) setIsLoadingProductFeeds(true);

    fetchProductFeeds().then(() => setIsLoadingProductFeeds(false));
  }, []); // eslint-disable-line

  useOnClickOutside(productSearchAreaRef, () => {
    setInputValue('');
    setSearchResults(undefined);
  });

  useEffect(() => {
    if (productFeeds?.length) {
      setSelectedProductFeedId(productFeeds[productFeeds.length - 1].feed_id); // last as default
    }
  }, [productFeeds]);

  // Find data for product that is already attached to embed as filter
  useEffect(() => {
    if (!value?.feedId || !value?.productId) {
      setSelectedProduct(undefined);
      return;
    }

    // We already have the product
    if (value.productId === selectedProduct?.id) {
      return;
    }

    fetch(`${config.commerceApiUrl}/product-api/${value.feedId}/${value.productId}`)
      .then((res) => res.json())
      .then((res: ProductResultResponse) => {
        setSelectedProduct(res?.products[0]);
      })
      .catch(console.error);
  }, [value, productFeeds, selectedProduct]);

  const searchProducts = (query?: string, feedId?: string) => {
    setIsSearching(true);

    if (!query || !feedId) {
      setIsSearching(false);
      return Promise.resolve();
    }

    fetch(`${config.commerceApiUrl}/feed-management-api/${feedId}/search?query=${query}*`)
      .then((res) => res.json())
      .then((res: ProductSearchResponse) => {
        setSearchResults(res?.results);
        setIsSearching(false);
      })
      .catch((err) => {
        console.error(err);
        setIsSearching(false);
      });
  };

  const debouncedSearch = useMemo(() => debounce(searchProducts, 300), []); // eslint-disable-line

  if (value?.feedId || value?.productId) {
    return (
      <div className={styles.productSearch}>
        {selectedProduct ? (
          <div className={styles.selectedProduct}>
            <img className={styles.productImg} src={selectedProduct.imageUrl} alt={selectedProduct.name} />

            <div className={styles.productInfo}>
              <span className={styles.productName}>{selectedProduct.name}</span>
              <span className={styles.productId}>{selectedProduct.id}</span>
            </div>
          </div>
        ) : (
          <div>
            <span className={styles.productValue}>{value.productId}</span>
          </div>
        )}

        <Button
          variant="neutral"
          size="small"
          action={() => {
            setInputValue('');
            handleChange(undefined);
          }}
        >
          Change product
        </Button>
      </div>
    );
  }

  if (isLoadingProductFeeds) {
    return <LoadingIndicator className="my-1 ml-28" />;
  }

  if (!productFeeds?.length) {
    return (
      <div className={styles.productSearch}>
        <div className={styles.emptyState}>
          Seems like you haven’t added any products yet. Start by{' '}
          <a target="_blank" rel="noopener noreferrer" href={`${config.commerceAppUrl}/${siteUrl}`}>
            adding first products
          </a>
          .
        </div>
      </div>
    );
  }

  return (
    <div ref={productSearchAreaRef} className={styles.productSearch}>
      <div className={styles.productSearchField}>
        {productFeeds && productFeeds.length > 1 && (
          <Select
            className={styles.productFeedSelect}
            value={selectedProductFeedId}
            onChange={(e: any) => {
              setSearchResults(undefined);
              setSelectedProductFeedId(e.target.value);
              setIsSearching(!!inputValue);
              debouncedSearch(inputValue, e.target.value);
              document.getElementById(id)?.focus();
            }}
          >
            {productFeeds.map((productFeed) => (
              <option key={productFeed.id} value={productFeed.feed_id}>
                {productFeed.name}
              </option>
            ))}
          </Select>
        )}

        <Input
          className={styles.productSearchInput}
          placeholder="Search for a product…"
          id={id}
          value={inputValue}
          name={name}
          type="text"
          onChange={(e) => {
            debouncedSearch(e.target.value, selectedProductFeedId);
            setInputValue(e.target.value);
            setIsSearching(!!e.target.value);

            if (e.target.value === '') {
              setSearchResults(undefined);
              return;
            }
          }}
        />
        {isSearching && (
          <div className={styles.productLoader}>
            <LoadingIndicator />
          </div>
        )}
      </div>

      {inputValue && !isSearching && !searchResults?.length && (
        <div className={classnames(styles.productSearchResults, styles.noResults)}>No results</div>
      )}

      {inputValue && !!searchResults?.length && (
        <div className={styles.productSearchResults}>
          {(searchResults || []).slice(0, 5).map((product: Product) => (
            <div
              className={styles.product}
              key={product.id}
              onClick={() => {
                handleChange({ feedId: selectedProductFeedId as string, productId: product.id });
                setSelectedProduct(product);
              }}
            >
              <img className={styles.productImg} src={product.imageUrl} alt={product.name} />

              <div className={styles.productInfo}>
                <span className={styles.productName}>{product.name}</span>
                <span className={styles.productId}>{product.id}</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  productFeeds: getProductFeeds(state),
});

const mapDispatchToProps = { fetchProductFeeds };

export default connect(mapStateToProps, mapDispatchToProps)(ProductSearch);
