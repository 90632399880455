import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'redux/store';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';

import useSiteUrl from 'hooks/useSiteUrl';
import useSections from 'hooks/api/useSections';
import { pathToDisplay } from 'services/routes';
import Page from 'components/Page';
import Button from 'components/Button';
import { getUserFirstName } from 'concepts/user';
import { getCurrentSiteId, getSitesLoadingStatus } from 'concepts/site';
import { initNewDisplayView, onCreate, getDisplayList, DisplayType } from 'concepts/display-list';
import LoadingIndicator from 'components/Loader/LoadingIndicator';
import { SLIDESHOW, displayNames } from 'constants/display-types';
import { SectionSelect, Select } from '../components/EmbedForm';

import styles from './ChooseSectionView.module.scss';
import AngleLink from 'components/AngleLink';

interface ChooseSectionParams {
  type: 'embed' | 'screen';
  displayType: DisplayType;
}

type ChooseSectionViewProps = ConnectedProps<typeof connector>;

const ChooseSectionView = ({
  siteId,
  displays,
  initNewDisplayView,
  isLoadingSite,
  onCreate,
  userFirstName,
}: ChooseSectionViewProps) => {
  // we need extra flag because useEffect does not fire instantly...
  const [selectedSectionIds, setSelectedSectionIds] = useState<number[] | undefined>(undefined);
  const [hasStartedView, setStarted] = useState(false);

  useEffect(() => {
    if (siteId) {
      setStarted(true);
      initNewDisplayView();
    }
  }, [initNewDisplayView, siteId]);

  const siteUrl = useSiteUrl();
  const { sections } = useSections(siteId, { pageSize: null });
  const { displayType, type } = useParams<ChooseSectionParams>();

  const isEmbedScreen = type === 'screen';
  const isSlideshow = displayType === SLIDESHOW;
  const isContentReady = hasStartedView && !!siteId && !isLoadingSite;
  const isLoaderVisible = !siteId || isLoadingSite;
  const isBackButtonVisible = displays.length > 0;

  return (
    <Page className={styles.displayPage}>
      <Helmet>
        <title>Flockler {'\u203A'} Choose Content</title>
      </Helmet>

      {isContentReady && (
        <>
          <h1 className={styles.mainHeading}>Would you like to display all content or a specific section?</h1>

          <p className={styles.reliefStressText}>
            No stress {userFirstName ? ` ${userFirstName}` : ''}, you can change this later.
          </p>

          <div
            className={classnames(styles.chooseSectionForm, {
              [styles.slideshowSectionForm]: displayType === SLIDESHOW,
            })}
          >
            {isSlideshow ? (
              <div className={styles.sectionDropdown}>
                <Select
                  className={styles.sectionDropdownSelect}
                  value={selectedSectionIds ? selectedSectionIds[0] : undefined}
                  onChange={(e) => setSelectedSectionIds(e.target.value ? [parseInt(e.target.value, 10)] : undefined)}
                >
                  <option value="">Display content from all sections</option>

                  {sortBy(sections, [(section) => section?.name.toLowerCase()]).map((section) => (
                    <option key={section.id} value={section.id}>
                      {section.name}
                    </option>
                  ))}
                </Select>
              </div>
            ) : (
              <SectionSelect
                className={styles.sectionSelect}
                selectedSectionsIds={selectedSectionIds || []}
                sections={sections || []}
                onChange={(section_ids: number[] | null) => setSelectedSectionIds(section_ids || undefined)}
              />
            )}

            <Button
              data-button-variant="success"
              className={styles.confirmSectionSelectButton}
              action={() =>
                onCreate({
                  displayType,
                  sections,
                  selectedSections: selectedSectionIds,
                  isEmbedScreen,
                })
              }
            >
              Create <span className={styles.displayType}>{get(displayNames, displayType, '')}</span> layout →
            </Button>
          </div>

          {isBackButtonVisible && (
            <div className={styles.back}>
              <Link to={pathToDisplay(siteUrl)} className={styles.backLink}>
                <AngleLink reverse>Back to layouts</AngleLink>
              </Link>
            </div>
          )}
        </>
      )}

      {isLoaderVisible && (
        <div className={styles.loader}>
          <LoadingIndicator />
        </div>
      )}
    </Page>
  );
};

const mapStateToProps = (state: RootState) => ({
  siteId: getCurrentSiteId(state),
  displays: getDisplayList(state),
  isLoadingSite: getSitesLoadingStatus(state),
  userFirstName: getUserFirstName(state),
});

const mapDispatchToProps = { initNewDisplayView, onCreate };

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ChooseSectionView);
