import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import Button from 'components/Button';
import Icon from 'components/Icon';

import styles from './SaveButton.module.scss';

interface SaveButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  action?: any;
  children?: React.ReactNode | string;
  className?: any;
  hasError: boolean;
  isSaving: boolean;
  savedMessage?: string;
  size?: any;
  style?: any;
  type?: any;
}

const SaveButton = ({
  action,
  children,
  className,
  hasError,
  isSaving,
  savedMessage,
  size,
  style,
  type,
  ...rest
}: SaveButtonProps) => {
  const [isSuccessMessageVisible, setSuccessMessageVisible] = useState(false);
  const [isSavingState, setSavingState] = useState(false);

  useEffect(() => {
    if (isSavingState && !isSaving && !hasError) {
      setSuccessMessageVisible(true);

      setTimeout(() => {
        setSuccessMessageVisible(false);
      }, 3000);
    }
  }, [isSavingState, isSaving, hasError]);

  useEffect(() => {
    setSavingState(isSaving);
  }, [isSaving]);

  const renderButtonContent = () => {
    if (isSaving) {
      return 'Saving…';
    }

    if (isSuccessMessageVisible) {
      return (
        <>
          <Icon type="checkmark" className={styles.icon} />
          {savedMessage || 'Saved'}
        </>
      );
    }

    return children || 'Save changes';
  };

  return (
    <Button
      variant="success"
      size={size}
      action={action}
      disabled={isSaving}
      type={type}
      className={classnames(className, { [styles.success]: isSuccessMessageVisible })}
      style={{ ...style, outline: 'none' }}
      {...rest}
    >
      {renderButtonContent()}
    </Button>
  );
};

export default SaveButton;
