import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Helmet } from 'react-helmet';
import './styles/index.scss';
import App from 'containers/App';
import * as serviceWorker from './serviceWorker';
import store, { history } from 'redux/store';
import { Router } from 'react-router-dom';

const container = document.getElementById('root');
const root = createRoot(container!);

const render = (Component: any) => {
  return root.render(
    <React.StrictMode>
      <Helmet>
        <title>Flockler</title>
      </Helmet>
      <Provider store={store}>
        <Router history={history}>
          <Component />
        </Router>
      </Provider>
    </React.StrictMode>
  );
};

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./containers/App', () => {
    render(App);
  });
}

render(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
