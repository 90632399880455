import AnimateFadeIn from 'components/AnimateFadeIn';
import Button from 'components/Button';
import FocusTrap from 'focus-trap-react';
import useCurrentSite from 'hooks/useCurrentSite';
import { useUserProfile } from 'hooks/useUserProfile';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { apiClient, PATHS, completeTask } from 'services/api';
import { useConsent } from 'services/cookie-policy';
import { pathToDashboard } from 'services/routes';
import useSWR from 'swr';

const userRoles: UserRoleType[] = ['manager', 'owner'];

export const IntroModal = () => {
  const [consent] = useConsent();
  const site = useCurrentSite();
  const user = useUserProfile();
  const history = useHistory();

  const shouldFetchTaskCompletions =
    site?.site_type === 'embed' && user?.all_user_roles[site?.id]?.some((role) => userRoles.includes(role.role_name));

  const { data, mutate } = useSWR<unknown[]>(
    shouldFetchTaskCompletions ? PATHS.USER_TASK_COMPLETION('view_intro') : null,
    apiClient.get
  );

  const handleClose = () => {
    mutate([1], { revalidate: false });
    completeTask(site.id, 'view_intro');
    history.push(pathToDashboard(site.site_url));
  };

  const [overlayNode, setOverlayNode] = useState<HTMLElement | null>(null);
  const [iframeNode, setIframeNode] = useState<HTMLIFrameElement | null>(null);

  const overlayRef = useCallback((node: HTMLDivElement) => {
    setOverlayNode(node?.parentElement ?? null);
  }, []);

  const iframeRef = useCallback((node: HTMLIFrameElement) => {
    setIframeNode(node ?? null);
  }, []);

  useEffect(() => {
    const overlay = overlayNode;
    const iframe = iframeNode;

    if (overlay && iframe) {
      const scrollToTop = () => {
        overlay.scrollTo({ top: 0 });
      };

      iframe.addEventListener('load', scrollToTop);

      return () => {
        iframe.removeEventListener('load', scrollToTop);
      };
    }
  }, [overlayNode, iframeNode]);

  if (consent && data?.length === 0) {
    return (
      <FocusTrap>
        <AnimateFadeIn
          className="fixed inset-0 z-[101] flex overflow-y-auto bg-white bg-opacity-75 py-8"
          animationDuration="0.4s"
          animationDelay="0.2s"
        >
          <div
            ref={overlayRef}
            role="dialog"
            className="m-auto flex w-11/12 max-w-7xl flex-col overflow-auto rounded bg-white shadow-xl lg:flex-row"
          >
            <div className="aspect-h-9 aspect-w-16 lg:basis-2/3">
              <iframe
                ref={iframeRef}
                className="bg-black"
                src="https://www.youtube.com/embed/ZFlehR70DRU?modestbranding=1&rel=0"
                title="Flockler Tutorials: How to get started"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
            <div className="px-4 pb-6 pt-8 lg:basis-1/3 lg:px-8">
              <AnimateFadeIn animationDuration="0.4s" animationDelay="0.6s">
                <h3 className="mb-6 text-2xl font-bold text-slate-700 delay-700">Welcome {user?.firstname} 👋</h3>
              </AnimateFadeIn>

              <AnimateFadeIn animationDuration="0.5s" animationDelay="1.4s">
                <div className="mb-6 text-slate-600 lg:mb-4">
                  <p className="mb-6">
                    Flockler helps marketers like you to display Instagram images, Facebook posts, Tweets, YouTube
                    videos, and more on any digital service to increase time spent on site and drive conversions.
                  </p>
                  <p className="mb-3 font-semibold text-slate-700">
                    <span className="hidden lg:inline">👈 </span>
                    <span>If you have ten minutes, watch the video and join the tour of Flockler.</span>
                  </p>
                  <p className="mb-6">
                    If you don’t have time right now, you can watch the video later and find answers to the most common
                    questions on our{' '}
                    <a href="https://help.flockler.com" target="_blank" rel="noopener noreferrer">
                      Help Center
                    </a>
                    . And don’t hesitate to hit the chat button at the bottom right corner of the service!
                  </p>
                  <p className="mb-4">Kind regards,</p>
                  <p className="font-semibold text-slate-700">Flockler Team</p>
                </div>
              </AnimateFadeIn>

              <AnimateFadeIn animationDuration="0.5s" animationDelay="2.8s">
                <Button className="lg:float-right" variant="primary" size="small" action={handleClose}>
                  Continue
                </Button>
              </AnimateFadeIn>
            </div>
          </div>
        </AnimateFadeIn>
      </FocusTrap>
    );
  }

  return null;
};
