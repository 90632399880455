import Button from 'components/Button';
import Modal from 'components/Modal';

interface ReviewFormsListItemDeleteModalProps {
  reviewForm: ReviewForm;
  deleteAction: () => void;
  dismissAction: () => void;
}

const ReviewFormsListItemDeleteModal = ({
  reviewForm,
  deleteAction,
  dismissAction,
}: ReviewFormsListItemDeleteModalProps) => (
  <Modal
    title={`Delete ${reviewForm.name}?`}
    actionButtons={[
      <div key="cancel">
        <Button variant="neutral" size="small" action={dismissAction}>
          Cancel
        </Button>
      </div>,
      <div key="confirm">
        <Button variant="danger" size="small" action={deleteAction}>
          Delete
        </Button>
      </div>,
    ]}
    dismissAction={dismissAction}
  >
    {`Are you sure that you want to delete the review form called "${reviewForm.name}"?`}
  </Modal>
);

ReviewFormsListItemDeleteModal.displayName = 'ReviewFormsListItemDeleteModal';
export default ReviewFormsListItemDeleteModal;
