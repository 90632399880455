import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import intersection from 'lodash/intersection';
import sortBy from 'lodash/sortBy';
import classnames from 'classnames';
import { RootState } from 'redux/store';
import formatPrice from 'services/format-price';

import { reportSentryError } from 'services/sentry';
import { Formik, Field } from 'formik';
import { getCurrentSite, fetchSiteSettings } from 'concepts/site';
import { fetchSubscription, getSubscription } from 'concepts/subscription';
import {
  createNewSubscription,
  getBillingEditFormValues,
  storeBillingFormValues,
  pauseFeedsOverTheLimit,
} from 'concepts/subscription-create';
import { getAvailablePlans, AvailablePlans, Plan, PlanType } from 'concepts/plan';
import { getUserLoadingState, getUserProfile } from 'concepts/user';
import { getCountries } from 'concepts/country';

import Button from 'components/Button';
import NoticeBox from 'components/NoticeBox';

import { Tablet, Desktop } from 'components/Responsive';
import PageLoader from 'components/Loader/PageLoader';
import HelpScoutLink from 'components/HelpScoutLink';
import SubscriptionSummary from '../SubscriptionSummary';
import CheckboxField from '../CheckboxField';
import { fetchVat, validateSubscription } from 'services/api';
import { getIsMonthlyPlan, getIsYearlyPlan } from 'services/plan';
import {
  preCheckVat,
  VatErrorKey,
  vatErrorLabels,
  vatPrefixForCountryCode,
  EU_MEMBER_COUNTRIES_WITH_VAT_ID,
} from 'services/vat';
import { pathToSettingsSubscriptionSuccess, pathToSettingsSubscriptionDetailsWithBankTransfer } from 'services/routes';

import styles from './SubscriptionDetailsWithBankTransfer.module.scss';
import LoadingIndicator from 'components/Loader/LoadingIndicator';

const getSelectedPlan = (planId: PlanType, plans: AvailablePlans) => {
  if (!plans) {
    return null;
  }

  if (getIsYearlyPlan(planId)) {
    return plans.yearly.find((plan: Plan) => plan.id === planId);
  }

  return plans.monthly.find((plan: Plan) => plan.id === planId);
};

const getMonthlyPlan = (planId: PlanType, plans: AvailablePlans) => {
  if (!plans) {
    return null;
  }

  return plans.monthly.find((plan: Plan) => plan.id === planId);
};

const getYearlyPlan = (planId: PlanType, plans: AvailablePlans) => {
  if (!plans) {
    return null;
  }

  return plans.yearly.find((plan: Plan) => plan.id === planId);
};

// returns true if any touched field has error
const touchedWithError = (errors: object, touched: object) => {
  if (!errors || !touched) {
    return false;
  }

  const errorKeys = Object.keys(errors);
  const touchedKeys = Object.keys(touched);

  return intersection(errorKeys, touchedKeys).length > 0;
};

// Using good old class component to achieve unmount lifecycle method!
// Replacing this with function component & hooks wasn't that straightforward.
class StoreFormOnUnmount extends React.Component<{ onUnmount: () => void }> {
  componentWillUnmount() {
    this.props.onUnmount();
  }

  render() {
    return null;
  }
}

const SubscriptionDetailsWithBankTransfer = ({
  availablePlans,
  createNewSubscription,
  countries,
  fetchSiteSettings,
  fetchSubscription,
  isLoadingUser,
  pauseFeedsOverTheLimit,
  savedFormValues,
  site,
  storeBillingFormValues,
  subscription,
  user,
}: SubscriptionDetailsWithBankTransferProps) => {
  const { plan } = useParams<{ plan: PlanType }>();

  const [vatRate, setVatRate] = useState(0);
  const [isCreatingSubscription, setIsCreatingSubscription] = useState(false);
  const [isValidVatId, setIsValidVatId] = useState(true);
  const [isValidatingVatId, setIsValidatingVatId] = useState(false);
  const [subscribeError, setSubscribeError] = useState<string | undefined>(undefined);
  const [vatError, setVatError] = useState<VatErrorKey | undefined>(undefined);
  const [validationErrors, setValidationErrors] = useState<any>(undefined);
  const [isVatInitialized, setIsVatInitialized] = useState(false);
  const [isMonthlyInitially, setIsMonthlyInitially] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setIsMonthlyInitially(getIsMonthlyPlan(plan));
  }, []); // eslint-disable-line

  const selectedPlan = getSelectedPlan(plan, availablePlans);
  const isYearlyPlan = getIsYearlyPlan(plan);
  const orderNumberOffset = [isMonthlyInitially].filter(Boolean).length;

  const updateVatRate = (countryCode: string, vatId: string) => {
    const country = countries.find((c) => c.code === countryCode);
    setVatError(undefined);
    setIsVatInitialized(true);
    setIsValidVatId(true);

    if (!country) {
      setVatError('country_missing');
      return;
    }

    return preCheckVat(country, vatId)
      .then(() => setIsValidatingVatId(true))
      .then(() => fetchVat(countryCode, vatId))
      .then((response: AxiosApiResponse) => {
        setVatRate(response.data.vat_rate);
        setIsValidVatId(!vatId || response.data.valid_vat_id);
        setIsValidatingVatId(false);
      })
      .catch(setVatError);
  };

  const debouncedUpdateVatRate = debounce(updateVatRate, 500);

  const initialValues = savedFormValues || {
    organizationName: subscription?.company?.name || '',
    streetAddress: subscription?.company?.street_address || '',
    postcode: subscription?.company?.postcode || '',
    city: subscription?.company?.city || '',
    country: subscription?.company?.country || '',
    vatId: subscription?.vat_id || '',
    email: subscription?.email || '',

    contactName: user.username || '',
    contactEmail: user.email || '',
    invoiceReference: '',

    hasAgreedTerms: false,
  };

  // If initial values contain vatId or country, update vat rate
  useEffect(() => {
    if ((initialValues.vatId || initialValues.country) && !isVatInitialized) {
      updateVatRate(initialValues.country, initialValues.vatId);
    }
  }, [initialValues, isVatInitialized]); // eslint-disable-line

  if (!availablePlans || !site || !user) {
    return <PageLoader />;
  }

  return (
    <div className={styles.SubscriptionWrapper}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => {
          if (isCreatingSubscription) {
            return;
          }

          const subscriptionPayload = {
            billing_type: 'bank_transfer',

            email: values.email,
            company: {
              street_address: values.streetAddress,
              postcode: values.postcode,
              city: values.city,
              country: values.country,
              name: values.organizationName,
            },
            terms: values.hasAgreedTerms,

            invoice_reference: values.invoiceReference,
            custom_plan_name: selectedPlan?.name,
            custom_billing_cycle: plan ? (getIsYearlyPlan(plan) ? 'yearly' : 'monthly') : '',
            contact_name: values.contactName,
            contact_email: values.contactEmail,
          };

          const selectedCountry = countries.find((c) => c.code === values.country);

          const validateSubscriptonValues = () =>
            validateSubscription(subscriptionPayload)
              .then(() => setValidationErrors(undefined))
              .catch((error) => {
                const { errors } = error?.response?.data || {};
                setValidationErrors(errors);
                return Promise.reject('subscriptionValidationFailed');
              });

          validateSubscriptonValues()
            .then(() => {
              if (selectedPlan?.id?.split('_')?.[0] === 'lite') {
                return pauseFeedsOverTheLimit().catch(() => {
                  console.log('error pausing feeds, carry on...');
                });
              }
            })
            .then(() => {
              return createNewSubscription({
                ...subscriptionPayload,
                site_id: site.id,
                vat_id: values.vatId,
              }).catch((error: any) => {
                setSubscribeError('Subscribing failed');
                reportSentryError('Subscription: Subscribing failed', { site_id: site.id });

                return Promise.reject();
              });
            })
            .then(() => {
              return Promise.all([fetchSubscription(site.id), fetchSiteSettings(site.id)]).then(() => {
                setIsCreatingSubscription(false);
                history.push(pathToSettingsSubscriptionSuccess(site.site_url));
              });
            })
            .catch((error: any) => {
              // Final catch to end loading state
              setIsCreatingSubscription(false);
            });
        }}
        // Client-side validation
        validate={(values) => {
          const errors = {} as any;

          // Company is required
          if (!values.organizationName) {
            errors.organizationName = 'Please enter a company.';
          }

          return errors;
        }}
      >
        {({ handleSubmit, handleChange, values, errors, touched }) => {
          const hasAnyValidationErrors = !isEmpty(validationErrors);
          // this could be in state to minimize .find calls
          const selectedCountry = countries.find((c) => c.code === values.country);

          return (
            <>
              <form onSubmit={handleSubmit} className={`${styles.SubscriptionForm} mx-auto max-w-md sm:max-w-lg`}>
                <StoreFormOnUnmount onUnmount={() => storeBillingFormValues(values)} />

                {isMonthlyInitially && (
                  <section className={styles.SubscriptionSection}>
                    <h2 className={styles.SubscriptionSectionTitle}>
                      <span className={styles.OrderNumber}>1</span>
                      Plan options
                    </h2>

                    <div className={styles.SubscriptionOptions}>
                      {[
                        {
                          value: 'monthly',
                          title: 'Bill monthly',
                        },
                        {
                          value: 'yearly',
                          title: 'Bill yearly',
                          savePercentage: 15,
                        },
                      ].map((billingCycle: any) => {
                        const isYearlySelected = billingCycle.value === 'yearly' && isYearlyPlan;
                        const isMonthlySelected = billingCycle.value === 'monthly' && !isYearlyPlan;
                        const isSelected = isYearlySelected || isMonthlySelected;

                        const currency = selectedPlan?.currency || 'EUR';
                        const planOption = `${plan.replace('_yearly', '')}${
                          billingCycle.value === 'yearly' ? '_yearly' : ''
                        }`;

                        const monthlyPrice =
                          billingCycle.value === 'yearly'
                            ? getYearlyPlan(planOption as PlanType, availablePlans)?.price_per_month
                            : getMonthlyPlan(planOption as PlanType, availablePlans)?.price_per_month;

                        return (
                          <label
                            className={classnames(styles.SubscriptionOption, {
                              [styles.selected]: isSelected,
                            })}
                            key={billingCycle.value}
                          >
                            <input
                              type="radio"
                              checked={isSelected}
                              onChange={() => {
                                history.replace(
                                  pathToSettingsSubscriptionDetailsWithBankTransfer(site?.site_url, planOption)
                                );
                              }}
                            />
                            <span className={styles.radioReplacement} />
                            <div className={styles.content}>
                              <div className={styles.info}>
                                <span className={styles.name}>{billingCycle.title}</span>
                                {monthlyPrice && (
                                  <span className={styles.description}>{formatPrice(monthlyPrice, currency)} / mo</span>
                                )}
                              </div>

                              {!!billingCycle.savePercentage && (
                                <div className={styles.saveTag}>-{billingCycle.savePercentage}%</div>
                              )}
                            </div>
                          </label>
                        );
                      })}
                    </div>
                  </section>
                )}

                <section className={styles.SubscriptionSection}>
                  <h2 className={styles.SubscriptionSectionTitle}>
                    <span className={styles.OrderNumber}>{orderNumberOffset + 1}</span>
                    Organization’s details
                  </h2>

                  <div className="grid gap-3 sm:grid-cols-3">
                    <div
                      className={classnames('sm:col-span-3', {
                        [styles.errorField]: errors?.organizationName && touched?.organizationName,
                      })}
                    >
                      <label className="label" htmlFor="organizationName">
                        <span>Organization name</span>
                      </label>
                      <Field
                        autoComplete="organization"
                        id="organizationName"
                        name="organizationName"
                        type="text"
                        required
                      />

                      {errors?.organizationName && touched?.organizationName && (
                        <div className={styles.formError}>{`${errors.organizationName}`}</div>
                      )}
                    </div>

                    <div
                      className={classnames('sm:col-span-3', {
                        [styles.errorField]: validationErrors?.company_street_address,
                      })}
                    >
                      <label className="label" htmlFor="streetAddress">
                        Organization address
                      </label>
                      <Field
                        autoComplete="street-address"
                        id="streetAddress"
                        name="streetAddress"
                        type="text"
                        required
                      />

                      {validationErrors?.company_street_address && (
                        <div className={styles.formError}>Please enter a street address.</div>
                      )}
                    </div>

                    <div>
                      <label className="label" htmlFor="postcode">
                        Postcode
                      </label>
                      <Field autoComplete="postal-code" id="postcode" name="postcode" type="text" required />
                    </div>

                    <div className="sm:col-span-2">
                      <label className="label" htmlFor="city">
                        City
                      </label>
                      <Field autoComplete="address-level2" id="city" name="city" type="text" required />
                    </div>

                    <div
                      className={classnames('sm:col-span-3', {
                        [styles.errorField]: validationErrors?.company_country,
                      })}
                    >
                      <label className="label" htmlFor="country">
                        Country
                      </label>
                      <select
                        autoComplete="country"
                        id="country"
                        name="country"
                        value={values.country}
                        onChange={(event: any) => {
                          debouncedUpdateVatRate(event.target.value, values.vatId);
                          handleChange(event);
                        }}
                        required
                      >
                        {!values.country && <option value="">Please select a country…</option>}
                        {sortBy(countries, 'name').map((country: Country) => (
                          <option key={country.code} value={country.code}>
                            {country.name}
                          </option>
                        ))}
                      </select>

                      {validationErrors?.company_country && (
                        <div className={styles.formError}>Please select a country.</div>
                      )}
                    </div>
                  </div>

                  {EU_MEMBER_COUNTRIES_WITH_VAT_ID.includes(values.country) && (
                    <>
                      <hr className={styles.divider} />

                      <div className={classnames(styles.FormFieldContext, 'mt-2')}>
                        <div
                          className={classnames('sm:col-span-3', { [styles.errorField]: !isValidVatId || vatError })}
                        >
                          <label className="label justify-start" htmlFor="vatId">
                            VAT ID number
                            <div className="label__context ml-2">
                              ({values.country === 'US' ? 'Optional' : 'Optional, adds reverse charge statement to your invoice'})
                            </div>
                          </label>
                          <div className="label__context mb-2">
                            Expected format: {vatPrefixForCountryCode(values.country)}12345678
                          </div>
                          <input
                            id="vatId"
                            name="vatId"
                            value={values.vatId}
                            onChange={(event: any) => {
                              debouncedUpdateVatRate(values.country, event.target.value);
                              handleChange(event);
                            }}
                            type="text"
                          />
                        </div>

                        {vatError && (
                          <div className="sm:col-span-3">
                            <p className={styles.formError}>{vatErrorLabels[vatError]}</p>
                          </div>
                        )}
                      </div>

                      {selectedCountry?.vat && (
                        <>
                          {isValidatingVatId && <NoticeBox type="neutral">Validating VAT ID…</NoticeBox>}

                          {!isValidatingVatId && !values.vatId && (
                            <NoticeBox type="neutral" withIcon="notice">
                              If your organization is a registered business in European Union, please enter your
                              organization’s{' '}
                              <a
                                className={styles.plainLink}
                                href="https://en.wikipedia.org/wiki/VAT_identification_number"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                VAT ID number
                              </a>{' '}
                              for the correct tax rate.
                            </NoticeBox>
                          )}

                          {!isValidatingVatId && values.vatId && !isValidVatId && (
                            <NoticeBox type="danger" withIcon>
                              We could not recognize this as a valid VAT ID number. Please type your VAT ID like this:
                              {vatPrefixForCountryCode(values.country)}12345678 and make sure your VAT ID is active.
                            </NoticeBox>
                          )}

                          {!isValidatingVatId && !!values.vatId && !vatError && isValidVatId && (
                            <NoticeBox type="success" withIcon>
                              VAT number is valid and the tax rate has been updated.
                            </NoticeBox>
                          )}
                        </>
                      )}
                    </>
                  )}

                  <hr className={styles.divider} />

                  <h2 className={styles.SubscriptionSectionTitle}>
                    <span className={styles.OrderNumber}>{orderNumberOffset + 2}</span>
                    Billing details
                  </h2>

                  <div className={classnames(styles.cardDetailsGrid, 'mt-2 grid gap-3')}>
                    <div className={classnames('sm:col-span-3', { [styles.errorField]: validationErrors?.email })}>
                      <label className="label" htmlFor="email">
                        Billing Email address
                      </label>
                      <div className="label__context mb-2">
                        Receipts and notifications will be sent to this email address
                      </div>

                      <Field
                        autoComplete="email"
                        disabled={isLoadingUser}
                        id="email"
                        name="email"
                        type="email"
                        required
                      />
                      {errors.email && touched.email && <div className={styles.formError}>{`${errors.email}`}</div>}

                      {validationErrors?.email && <div className={styles.formError}>Invalid email address</div>}
                    </div>

                    <div className="sm:col-span-3">
                      <label className="label" htmlFor="contactName">
                        Contact person name
                      </label>
                      <Field id="contactName" name="contactName" type="text" required />

                      {errors?.contactName && touched?.contactName && (
                        <div className={styles.formError}>{`${errors.contactName}`}</div>
                      )}
                    </div>
                    <div className="sm:col-span-3">
                      <label className="label" htmlFor="contactEmail">
                        Contact person email address
                      </label>
                      <Field id="contactEmail" name="contactEmail" type="email" required />

                      {errors?.contactEmail && touched?.contactEmail && (
                        <div className={styles.formError}>{`${errors.contactEmail}`}</div>
                      )}
                    </div>
                    <div className="sm:col-span-3">
                      <label className="label" htmlFor="invoiceReference">
                        Reference to the invoice
                      </label>
                      <div className="label__context mb-2">
                        For example, PO, project code, contact person name – anything your accountant would love to see
                        in the invoice. (Optional)
                      </div>
                      <Field id="invoiceReference" name="invoiceReference" as="textarea" />
                    </div>
                  </div>
                </section>

                <section className={styles.SubscriptionSection}>
                  <div
                    className={classnames('mb-8', {
                      [styles.errorField]: validationErrors?.terms,
                    })}
                  >
                    <CheckboxField name="hasAgreedTerms" checked={!!values.hasAgreedTerms} required>
                      I agree to Flockler’s{' '}
                      <a href="https://flockler.com/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                        Terms &amp; Conditions
                      </a>
                      ,{' '}
                      <a href="https://flockler.com/sla" target="_blank" rel="noopener noreferrer">
                        SLA
                      </a>{' '}
                      and{' '}
                      <a href="https://www.relaycommerce.io/privacy-policy" target="_blank" rel="noopener noreferrer">
                        Privacy Policy
                      </a>
                    </CheckboxField>

                    {validationErrors?.terms && (
                      <div className={styles.formError}>
                        You must agree to the Terms & Conditions, SLA, and Privacy Policy to purchase a plan.
                      </div>
                    )}
                  </div>
                </section>

                <Tablet>
                  {selectedPlan && (
                    <SubscriptionSummary
                      isLoading={isValidatingVatId}
                      isYearlyPlan={isYearlyPlan}
                      selectedPlan={selectedPlan}
                      vatRate={vatRate}
                      isVatNeeded={!!selectedCountry?.vat}
                    />
                  )}
                </Tablet>

                <div className={styles.SubscriptionActions}>
                  <Button
                    type="submit"
                    variant="success"
                    size="large"
                    disabled={!isValidVatId || touchedWithError(errors, touched) || isCreatingSubscription}
                  >
                    {isCreatingSubscription ? 'Confirming subscription…' : 'Confirm subscription'}
                  </Button>

                  {isCreatingSubscription && <LoadingIndicator className="ml-4" />}

                  {subscribeError && (
                    <div className={styles.formError}>
                      <div className={styles.formErrorTitle}>{subscribeError || 'Subscribing failed'}</div>
                      Please{' '}
                      <HelpScoutLink
                        messageText="I tried to subscribe, but it failed. Can you help?"
                        style={{ color: 'inherit', textDecoration: 'underline' }}
                      >
                        contact us
                      </HelpScoutLink>
                      .
                    </div>
                  )}

                  {hasAnyValidationErrors && (
                    <div className={styles.formError}>
                      <div className={styles.formErrorTitle}>Oops, something went wrong.</div>
                      Please check the form for errors.
                      <br />
                      <HelpScoutLink style={{ color: 'inherit', textDecoration: 'underline' }}>
                        Chat with us
                      </HelpScoutLink>{' '}
                      if the issue persists.
                    </div>
                  )}
                </div>
              </form>

              <Desktop>
                {selectedPlan && (
                  <SubscriptionSummary
                    isLoading={isValidatingVatId}
                    isYearlyPlan={isYearlyPlan}
                    isVatNeeded={!!selectedCountry?.vat}
                    selectedPlan={selectedPlan}
                    vatRate={vatRate}
                  />
                )}
              </Desktop>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  availablePlans: getAvailablePlans(state),
  countries: getCountries(state),
  savedFormValues: getBillingEditFormValues(state),
  site: getCurrentSite(state),
  subscription: getSubscription(state),
  user: getUserProfile(state),
  isLoadingUser: getUserLoadingState(state),
});

const mapDispatchToProps = {
  createNewSubscription,
  fetchSiteSettings,
  fetchSubscription,
  pauseFeedsOverTheLimit,
  storeBillingFormValues,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type SubscriptionDetailsWithBankTransferProps = ConnectedProps<typeof connector>;

export default connector(SubscriptionDetailsWithBankTransfer);
