import isNil from 'lodash/isNil';
import { connect } from 'react-redux';
import { fetchMediaTrackers, hasError, updateMediaTrackerAccountId } from 'concepts/media-tracker';
import { showSuccess } from 'concepts/push-notifications';
import { fetchUser, getSocialMediaAccounts } from 'concepts/user';
import connectAccount from 'services/connect-account';
import { findLatestServiceAccount } from 'services/social-media-account';
import Button from 'components/Button';
import NoticeBox from 'components/NoticeBox';
import HelpScoutLink from 'components/HelpScoutLink';
import styles from './AutomatedFeedError.module.scss';
import { RootState } from 'redux/store';

import { ReactComponent as IconExternalLink } from 'images/icons/icon-external-link.svg';
import useCurrentSite from 'hooks/useCurrentSite';
import { pathToSettingsApiKeys } from 'services/routes';
import { fetchLinkedinOrganizations } from 'services/api';
import { Link } from 'react-router-dom';

const getFeedServiceName = (service: MediaTrackerService) => {
  switch (service) {
    case 'facebook':
      return 'Facebook';
    case 'google_review':
      return 'Google';
    case 'instagram_graph_api':
      return 'Instagram';
    case 'twitter':
    case 'twitter_v2':
      return 'Twitter';
    case 'rss':
      return 'RSS';
    case 'youtube':
      return 'YouTube';
    case 'linkedin':
      return 'LinkedIn';
    case 'tiktok':
      return 'TikTok';
    default:
      return service;
  }
};

const getError = (errors: MediaTrackerError[], errorTypes: string[]) =>
  errors.find((error) => errorTypes.includes(error.error_class));

const getErrorMessage = (feed: MediaTracker) => {
  const { service, media_tracker_state } = feed;
  const { current_errors } = media_tracker_state;
  const serviceName = getFeedServiceName(service);

  const fbAccountManagingError = getError(current_errors, ['FacebookAccountManagingError']);

  if (service === 'twitter') {
    return (
      <>
        We are not able to fetch new content at the moment due to Twitter’s API changes. We are building a new solution
        to collect hashtag and keyword mentions.
      </>
    );
  }

  // 464 = Sessions for the user are not allowed because the user is not a confirmed user
  if (fbAccountManagingError?.details?.fb_error_subcode === '464') {
    return (
      <>
        According to Facebook’s error message, the connected user will need to verify their Facebook account to resolve
        this. Alternatively, click 'Fix now' and reconnect with another FB account.
      </>
    );
  }

  if (fbAccountManagingError) {
    return (
      <>
        According to Facebook’s error message, the connected user will need to log in to the Facebook to resolve this.
        Alternatively, click 'Fix now' and reconnect with another FB account.
      </>
    );
  }

  if (getError(current_errors, ['TwitterV2BearerTokenInvalidError'])) {
    return (
      <>
        The Twitter API key is invalid. You need to go the settings page in Flockler, and update your Twitter OAuth
        Bearer Token before we can continue updating fresh content.
      </>
    );
  }

  if (getError(current_errors, ['TwitterV2BearerTokenMissingError'])) {
    return (
      <>
        No Twitter API key found. You need to go the settings page in Flockler, and add your Twitter OAuth Bearer Token
        before we can continue updating fresh content.
      </>
    );
  }

  if (getError(current_errors, ['TwitterV2UnknownError'])) {
    return (
      <>
        Could not poll content from X. If the problem persists, please
        <HelpScoutLink className={styles.supportLink}>contact Flockler’s support team</HelpScoutLink>.
      </>
    );
  }

  if (getError(current_errors, ['RateLimitError'])) {
    let rateLimitErrorLink = null;
    // if (service === 'twitter') {
    //   rateLimitErrorLink = 'https://flockler.com/twitter-request-limit';
    // } else
    if (service === 'instagram_graph_api') {
      rateLimitErrorLink = 'https://flockler.com/instagram-is-limiting-the-number-of-requests';
    } else if (service === 'facebook') {
      rateLimitErrorLink = 'https://flockler.com/facebook-limiting-requests';
    }

    return (
      <>
        {serviceName} is limiting the number of requests for your account and the feed is not updating at a normal pace.{' '}
        {rateLimitErrorLink && (
          <a className={styles.readMoreLink} href={rateLimitErrorLink} target="_blank" rel="noreferrer noopener">
            See tips on how to solve this… <IconExternalLink />
          </a>
        )}
      </>
    );
  }

  if (getError(current_errors, ['AccessTokenError'])) {
    return (
      <>
        {serviceName} requests you to reconnect your account with Flockler before we can continue updating fresh
        content. <span className="inline-block">Click 'Fix now'</span> and log in with your{' '}
        {service === 'instagram_graph_api' ? 'Facebook' : serviceName} account.
        {service === 'linkedin' && (
          <> Your account needs to have access to {getMatchAuthorVisibleName(feed)} LinkedIn Page.</>
        )}
      </>
    );
  }

  if (getError(current_errors, ['SpamProtectionError'])) {
    return (
      <>
        It seems that {serviceName} has restricted the use of your connected account and the feed is not updating fresh
        content.{' '}
        <a
          className={styles.readMoreLink}
          href="https://flockler.com/twitter-restriction"
          target="_blank"
          rel="noreferrer noopener"
        >
          See tips on how to solve this… <IconExternalLink />
        </a>
      </>
    );
  }

  if (getError(current_errors, ['MediaTrackerAccountMissingError'])) {
    return (
      <>
        {serviceName} requests you to reconnect your account with Flockler before we can continue updating fresh
        content. <span className="inline-block">Click 'Fix now'</span> and log in with your{' '}
        {service === 'instagram_graph_api' ? 'Facebook' : serviceName} account.
        {service === 'linkedin' && (
          <> Your account needs to have access to {getMatchAuthorVisibleName(feed)} LinkedIn Page.</>
        )}
      </>
    );
  }

  if (media_tracker_state?.account_not_valid) {
    return (
      <>
        We are unable to update fresh content. Please <span className="inline-block">click 'Fix now'</span> and
        reconnect your {service === 'instagram_graph_api' ? 'Facebook' : serviceName} account.
      </>
    );
  }

  if (getError(current_errors, ['TemporaryInternalError', 'LockingError'])) {
    return (
      <>
        There seems to be a temporary error with your feed. Please check back later and{' '}
        <HelpScoutLink className={styles.supportLink}>contact Flockler’s support team</HelpScoutLink> if the issue
        persists.
      </>
    );
  }

  if (getError(current_errors, ['PageAccessError'])) {
    return (
      <>
        It seems that the {serviceName} Page is not public or it has been removed and the feed is not updating fresh
        content. Please check that the {serviceName} Page is public and still exists. If the issue persists, please
        <HelpScoutLink className={styles.supportLink}>contact Flockler’s support team</HelpScoutLink>.
      </>
    );
  }

  if (getError(current_errors, ['PagePermissionsError'])) {
    return (
      <>
        Collecting posts mentioning a specific Page requires admin access, and the feed is not updating fresh content.{' '}
        <a
          className={styles.readMoreLink}
          href="https://flockler.com/fb-admin-access-required"
          target="_blank"
          rel="noreferrer noopener"
        >
          See tips on how to solve this… <IconExternalLink />
        </a>
      </>
    );
  }

  if (getError(current_errors, ['FacebookWebhooksPageTokenMissingError'])) {
    return (
      <>
        Connected account cannot access this Facebook Page. Please connect an account with access to the Page. If the
        issue persists, please
        <HelpScoutLink className={styles.supportLink}>contact Flockler’s support team</HelpScoutLink>.
      </>
    );
  }

  if (getError(current_errors, ['UserFeedPermissionsError'])) {
    // if (service === 'twitter') {
    //   return (
    //     <>
    //       It seems the Twitter account is private or otherwise unavailable and the feed is not updating fresh content.
    //       If you are the owner of this account, please log in to Twitter and make sure the account is public and that
    //       there are no other errors. If the issue persists, please{' '}
    //       <HelpScoutLink className={styles.supportLink}>contact Flockler’s support team</HelpScoutLink>.
    //     </>
    //   );
    // }

    if (service === 'instagram_graph_api') {
      return (
        <>
          Gathering posts mentioning a specific Instagram account requires admin access, and the feed is not updating
          fresh content.{' '}
          <a
            className={styles.readMoreLink}
            href="https://flockler.com/ig-admin-access-required"
            target="_blank"
            rel="noreferrer noopener"
          >
            See tips on how to solve this… <IconExternalLink />
          </a>
        </>
      );
    }

    // Fallback for other services from Newsroom
    return 'Permissions are preventing your account from fetching content';
  }

  if (getError(current_errors, ['InstagramBusinessAccountMissingError'])) {
    return (
      <>
        We are unable to access the Instagram Business account and the feed is not updating fresh content.{' '}
        <a
          className={styles.readMoreLink}
          href="https://flockler.com/unable-to-access-instagram-business-account"
          target="_blank"
          rel="noreferrer noopener"
        >
          See tips on how to solve this… <IconExternalLink />
        </a>
      </>
    );
  }

  if (getError(current_errors, ['FacebookPageEndpointUnavailableOnNewPageExperienceError'])) {
    return (
      <>
        This Facebook page has been updated to the New Pages Experience on Facebook, and this content is no longer
        available due to the update. The feed is not updating fresh content. There is currently no way to collect this
        content, and this feed will remain unable to update indefinitely. Please{' '}
        <HelpScoutLink className={styles.supportLink}>contact Flockler support</HelpScoutLink> if you are unsure what to
        do about this.{' '}
        <a
          className={styles.readMoreLink}
          href="https://www.facebook.com/business/help/2752670058165459"
          target="_blank"
          rel="noreferrer noopener"
        >
          Read more about New Pages Experience… <IconExternalLink />
        </a>
      </>
    );
  }

  if (getError(current_errors, ['FacebookVisitorPostsUnavailableOnNewPageExperienceError'])) {
    return (
      <>
        This Facebook page has been updated to the New Pages Experience on Facebook, and visitor posts are no longer
        available due to the update. The feed is not updating fresh content. There is currently no way to collect
        visitor posts from pages using New Page Experience, and this feed will remain unable to update indefinitely.
        Please <HelpScoutLink className={styles.supportLink}>contact Flockler support</HelpScoutLink> if you are unsure
        what to do about this.{' '}
        <a
          className={styles.readMoreLink}
          href="https://www.facebook.com/business/help/2752670058165459"
          target="_blank"
          rel="noreferrer noopener"
        >
          Read more about New Pages Experience… <IconExternalLink />
        </a>
      </>
    );
  }

  if (getError(current_errors, ['PageRequestLimitError'])) {
    return (
      <>
        Facebook is limiting the number of requests for your Page and the feed is not updating at a normal pace.{' '}
        <a
          className={styles.readMoreLink}
          href="https://flockler.com/facebook-page-requests-limited"
          target="_blank"
          rel="noreferrer noopener"
        >
          See tips on how to solve this… <IconExternalLink />
        </a>
      </>
    );
  }

  if (getError(current_errors, ['UserNotFoundError'])) {
    return (
      <>
        It seems that the account is private, it doesn’t exist or it is otherwise unavailable at the moment and the feed
        is not updating fresh content. Please check that the {serviceName} account is public and the username still
        exists. If the issue persists, please contact Flockler support.
      </>
    );
  }

  const hasSections = feed.media_tracker_subscriptions.some(
    (mtSubscription) => mtSubscription.subscriber_type === 'Section' && !isNil(mtSubscription.subscriber_id)
  );

  if (!hasSections) {
    return 'The section for this feed has been deleted. Please create a new automated feed to replace this.';
  }

  if (getError(current_errors, ['PrivateAccountAccessError'])) {
    return (
      <>
        It seems that the account is private or otherwise unavailable at the moment and the feed is not updating fresh
        content. Please check that the [social media channel] account is public. If the issue persists, please{' '}
        <HelpScoutLink className={styles.supportLink}>contact Flockler’s support team</HelpScoutLink>.
      </>
    );
  }

  if (
    getError(current_errors, [
      'ConnectionError',
      'InternalServerError',
      'ServiceUnavailable',
      'TimeoutError',
      'UnknownRssError',
    ])
  ) {
    return (
      <>
        {serviceName} seems to have temporary issues in their services. Please check back later and{' '}
        <HelpScoutLink className={styles.supportLink}>contact Flockler’s support team</HelpScoutLink> if the issue
        persists.
      </>
    );
  }

  if (getError(current_errors, ['InstagramGraphApiHashtagDoesNotExistError'])) {
    return (
      <>
        Content for one or more Instagram hashtags is unavailable at the moment. The feed is not updating fresh content
        for the affected hashtags. This behavior is expected for hashtags that currently have no content on Instagram.
        The issue should get resolved automatically when content becomes available. If the issue persists, please{' '}
        <HelpScoutLink className={styles.supportLink}>contact Flockler support</HelpScoutLink>.
      </>
    );
  }

  if (getError(current_errors, ['FacebookAccountMissingInstagramBasicScopeError'])) {
    return (
      <>
        The connected Facebook account cannot access your Instagram account. The feed is not updating fresh content.
        Please check{' '}
        <a
          className={styles.readMoreLink}
          href="https://flockler.com/facebook-page-requests-limited"
          target="_blank"
          rel="noreferrer noopener">Facebook settings</a>{' '}
          to make sure the connected Facebook account has access to the Instagram account.
        Or, you can try <span className="inline-block">clicking 'Fix now'</span> and connecting a different Facebook account. If the issue persists, please{' '}
        <HelpScoutLink className={styles.supportLink}>contact Flockler support</HelpScoutLink>.
      </>
    );
  }

  if (media_tracker_state.filtering_parameters_not_valid) {
    return (
      <>
        We are unable to update fresh content. Please{' '}
        <HelpScoutLink className={styles.supportLink}>contact Flockler’s support team</HelpScoutLink> to resolve this.
      </>
    );
  }

  if (media_tracker_state.unknown_error) {
    if (!getIsFixableByReconnect(feed)) {
      return (
        <>
          We are unable to update fresh content. If the issue persists, please{' '}
          <HelpScoutLink className={styles.supportLink}>contact Flockler’s support team</HelpScoutLink>
        </>
      );
    }

    return (
      <>
        We are unable to update fresh content. Please click 'Fix now' and reconnect your social media account. If the
        issue persists, please{' '}
        <HelpScoutLink className={styles.supportLink}>contact Flockler’s support team</HelpScoutLink>.
      </>
    );
  }

  return '';
};

const getMatchAuthorVisibleName = (feed: MediaTracker) => {
  const authorFilter = feed.media_tracker_filters.find((f) => f.filter_type === 'match_author');
  return (authorFilter?.parameters || []).find((f) => f.name === 'visible_name')?.value;
};

const getIsApiKeyError = (feed: MediaTracker) => {
  const apiKeyErrors = ['TwitterV2BearerTokenInvalidError', 'TwitterV2BearerTokenMissingError'];
  return !!feed.media_tracker_state.current_errors.find((e) => apiKeyErrors.includes(e.error_class));
};

const getIsFixableByReconnect = (feed: MediaTracker) => {
  const { media_tracker_state, service } = feed;
  const { current_errors } = media_tracker_state;

  // Service that cannot be "reconnected"
  if (['pinterest', 'rss', 'flickr', 'twitter_v2', 'twitter'].includes(service)) {
    return false;
  }

  if (media_tracker_state.account_not_valid) {
    return true;
  }

  const errorWithService = getError(current_errors, [
    'ConnectionError',
    'InternalServerError',
    'ServiceUnavailable',
    'TimeoutError',
    'UnknownRssError',
  ]);

  if (errorWithService) {
    return false;
  }

  const hasFixableError = !!getError(current_errors, [
    'RateLimitError',
    'MediaTrackerAccountMissingError',
    'PageAccessError',
    'PagePermissionsError',
    'PageRequestLimitError',
    'InstagramBusinessAccountMissingError',
    'UserFeedPermissionsError',
    'SpamProtectionError',
    'FacebookAccountManagingError',
    'AccessTokenError',
    'FacebookWebhooksPageTokenMissingError',
    'FacebookAccountMissingInstagramBasicScopeError',
  ]);

  return hasFixableError || media_tracker_state.unknown_error;
};

type AutomatedFeedErrorProps = {
  feed: MediaTracker;
  fetchMediaTrackers: any;
  fetchUser: any;
  showSuccess: (message: string) => void;
  socialMediaAccounts?: UserSocialMediaAccount[];
  updateMediaTrackerAccountId: (feed: MediaTracker, accountId: number) => Promise<any>;
};

const AutomatedFeedError = ({
  feed,
  fetchMediaTrackers,
  fetchUser,
  showSuccess,
  socialMediaAccounts,
  updateMediaTrackerAccountId,
}: AutomatedFeedErrorProps) => {
  const errorMessage = getErrorMessage(feed);
  const isFixableByReconnect = getIsFixableByReconnect(feed);
  const isApiKeyError = getIsApiKeyError(feed);
  const site = useCurrentSite();

  if (!errorMessage) {
    return null;
  }

  const onFeedFix = () => {
    const latestServiceAccount = findLatestServiceAccount(feed.service, socialMediaAccounts);

    const connectAccountCallback = () => {
      fetchUser()
        .then((user: User) => {
          const updatedAccount = findLatestServiceAccount(feed.service, user?.social_media_accounts);

          // Check that new account is connected
          if (
            !updatedAccount ||
            (latestServiceAccount && updatedAccount?.connected_at === latestServiceAccount?.connected_at)
          ) {
            return Promise.reject();
          }

          // Update account if it has been changed
          if (updatedAccount.id !== feed.social_media_account_id) {
            // If TikTok, check that the account is the same as the account collected by the feed

            if (feed.service === 'tiktok') {
              const authorFilter = feed.media_tracker_filters.find((f) => f.filter_type === 'match_author');
              const feedUsername = authorFilter?.parameters?.[0]?.value;

              if (feedUsername?.toLocaleLowerCase() !== updatedAccount.name.toLocaleLowerCase()) {
                alert(
                  `The account you connected is not the same as the account collected by the feed.\n\nPlease connect the TikTok account with username: ${feedUsername}`
                );
                return Promise.reject();
              }
            }

            // If LinkedIn, check that the connected account has access to feed page/organization
            if (feed.service === 'linkedin') {
              const authorFilter = feed.media_tracker_filters.find((f) => f.filter_type === 'match_author');
              const feedOrgId = authorFilter?.parameters?.find((p) => p.name === 'name')?.value;
              const feedOrgName = authorFilter?.parameters?.find((p) => p.name === 'visible_name')?.value;

              return fetchLinkedinOrganizations().then((response) => {
                const hasAccessToFeedOrg = response?.data?.organizations?.some((org) => org.id === feedOrgId);

                if (!hasAccessToFeedOrg) {
                  alert(
                    `The account you connected doesn’t have access to ${feedOrgName} Linkedin Page.\n\nPlease connect the LinkedIn account which has account to that Page.`
                  );
                  return Promise.reject();
                }

                return updateMediaTrackerAccountId(feed, updatedAccount.id);
              });
            }

            return updateMediaTrackerAccountId(feed, updatedAccount.id);
          }
        })
        .then(fetchMediaTrackers)
        .then((response: any) => {
          const updatedFeed = (response?.payload || []).find((updated: MediaTracker) => updated.id === feed.id);

          // Check that errors are gone!
          if (updatedFeed && !hasError(updatedFeed)) {
            showSuccess('Feed fixed');
          }
        })
        .catch(() => console.log('Feed was not fixed'));
    };

    connectAccount(feed.service, connectAccountCallback);
  };

  return (
    <>
      <NoticeBox className={styles.error} type="danger">
        {errorMessage}
      </NoticeBox>

      {isFixableByReconnect && (
        <Button variant="primary" size="small" action={onFeedFix} className="mt-4">
          Fix now
        </Button>
      )}

      {isApiKeyError && (
        <Button
          variant="primary"
          size="small"
          to={pathToSettingsApiKeys(site.site_url)}
          renderComponent={Link}
          className="mt-4"
        >
          Open settings
        </Button>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  socialMediaAccounts: getSocialMediaAccounts(state),
});

const mapDispatchToProps = {
  fetchUser,
  fetchMediaTrackers,
  updateMediaTrackerAccountId,
  showSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(AutomatedFeedError);
