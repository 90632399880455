import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import { getSiteUrl } from 'concepts/site';
import { getUserSiteRoles, isAdminUser } from 'concepts/user';
import { getSubscription, Subscription } from 'concepts/subscription';
import { pathToSettingsSubscriptionOverview } from 'services/routes';
import { helpScoutLinkClickAction } from 'components/HelpScoutLink';
import config from 'config';

import Button from 'components/Button';
import Modal from 'components/Modal';

type SubscriptionExpiredNoticeProps = {
  isAdmin?: boolean;
  siteUrl: string;
  subscription: Subscription;
  userSiteRoles: UserRole[];
};

const SubscriptionExpiredNotice = ({
  isAdmin,
  siteUrl,
  subscription,
  userSiteRoles,
}: SubscriptionExpiredNoticeProps) => {
  const [isVisible, setVisibility] = useState(true);

  if (!isVisible) {
    return null;
  }

  const isOwner = !!(userSiteRoles || []).find((role: UserRole) => role.role_name === 'owner');
  const hasAccessToSubscription = isOwner || isAdmin;
  const isTrialExpired = !subscription?.stripe_subscription_exists;

  return (
    <Modal
      title={isTrialExpired ? 'Your trial has expired' : 'Your subscription has ended'}
      style={{ zIndex: 99 }} // navigation stays on top of modal visible
      actionButtons={
        hasAccessToSubscription
          ? [
              <Button
                key="contactUs"
                variant="neutral"
                size="small"
                action={(event: any) => {
                  helpScoutLinkClickAction('', true, event);
                }}
                renderComponent="a"
                href={`mailto:${config.supportEmail}`}
              >
                {isTrialExpired ? 'Contact us to extend your trial' : 'Contact us'}
              </Button>,

              <div>
                <Button
                  key="selectPlan"
                  variant="success"
                  size="small"
                  to={pathToSettingsSubscriptionOverview(siteUrl)}
                  renderComponent={Link}
                >
                  Select a monthly plan
                </Button>
              </div>,
            ]
          : [
              <Button
                key="contactUs"
                variant="neutral"
                size="small"
                action={(event: any) => {
                  helpScoutLinkClickAction('', true, event);
                }}
                renderComponent="a"
                href={`mailto:${config.supportEmail}`}
              >
                {isTrialExpired ? 'Contact us to extend your trial' : 'Contact us'}
              </Button>,
            ]
      }
      dismissAction={isAdmin ? () => setVisibility(false) : undefined}
    >
      {/* Owner or Admin */}
      {hasAccessToSubscription && <p>Please select a monthly plan to continue using Flockler.</p>}

      {/* Manager or Editor when Trial ended */}
      {!hasAccessToSubscription && isTrialExpired && <p>Didn’t have enough time to test?</p>}

      {/* Manager or Editor when Site expired */}
      {!hasAccessToSubscription && !isTrialExpired && (
        <p>You need an active subscription to continue using Flockler.</p>
      )}
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => ({
  isAdmin: isAdminUser(state),
  siteUrl: getSiteUrl(state),
  subscription: getSubscription(state),
  userSiteRoles: getUserSiteRoles(state),
});

export default connect(mapStateToProps)(SubscriptionExpiredNotice);
