import Button from 'components/Button';
import HelpScoutLink, { helpScoutLinkClickAction } from 'components/HelpScoutLink';
import Modal from 'components/Modal';

interface AutoRefreshNoticeProps {
  onClose: () => void;
}

const AutoRefreshNotice = ({ onClose }: AutoRefreshNoticeProps) => {
  return (
    <Modal
      title="Automatic updates"
      actionButtons={[
        <Button size="small" action={onClose} variant="neutral">
          Close
        </Button>,
        <Button size="small" action={helpScoutLinkClickAction} variant="primary">
          Contact us
        </Button>,
      ]}
      dismissAction={onClose}
    >
      <p>
        Are you looking to use Flockler in an event and would like the layout to show new posts automatically and
        without refresh? Please click &apos;Display&apos; in the navigation and create a new Wall layout meant for digital screens.{' '}
      </p>

      <p>
        If you’d need the layout to refresh automatically for another reason,
        please <HelpScoutLink>send&nbsp;us a message via live chat</HelpScoutLink> and share us more information about your use&nbsp;case.
      </p>
    </Modal>
  );
};

export default AutoRefreshNotice;
