import React, { ButtonHTMLAttributes } from 'react';
import classnames from 'classnames';
import styles from './Button.module.scss';
import classNames from 'classnames';

export type ButtonVariant = 'neutral' | 'warning' | 'danger' | 'success' | 'primary' | 'naked';
interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
  children: any;
  className?: string;
  buttonTextClassname?: string;
  type?: 'button' | 'submit' | 'reset';
  linkLike?: boolean;
  variant?: ButtonVariant;
  size?: 'tiny' | 'small' | 'medium' | 'large';
  icon?: React.ReactNode;
  action?: any;
  style?: object;
  title?: string;
  to?: string;
  href?: string;
  renderComponent?: any;
}

const Button = ({
  disabled = false,
  children,
  className,
  buttonTextClassname,
  linkLike = false,
  type = 'button',
  variant = 'success',
  size = 'medium',
  icon = null,
  action,
  style,
  title,
  to,
  href,
  renderComponent,
  ...rest
}: IButtonProps) => {
  const ButtonComponent = renderComponent || 'button';

  return (
    <ButtonComponent
      className={classnames(styles.Button, className, linkLike ? styles.ButtonLinkLike : null)}
      type={type}
      disabled={disabled}
      data-button-variant={variant}
      data-button-size={size}
      onClick={action}
      style={style}
      title={title}
      to={to}
      href={href}
      {...rest}
    >
      {icon}
      <span className={classNames(styles.ButtonText, buttonTextClassname)}>{children}</span>
    </ButtonComponent>
  );
};

export default Button;
