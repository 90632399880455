import { useState } from 'react';
import { format, differenceInHours } from 'date-fns';
import { Link } from 'react-router-dom';

import type { Subscription, SubscriptionInfo, SubscriptionPayload } from 'concepts/subscription';
import CancellationConfirmModal from '../CancellationConfirmModal';
import Button from 'components/Button';
import Heading from 'components/Heading';
import HelpScoutLink from 'components/HelpScoutLink';

import styles from './Cancellation.module.scss';
import { type StripeSubscription } from 'concepts/stripe-subscription';
import { pathToSettingsSubscriptionEditPaymentDetails } from 'services/routes';
import useSiteUrl from 'hooks/useSiteUrl';

type CancellationProps = {
  cancelSubscriptionWithMessage: (message: string) => Promise<any>;
  currentPlanName?: string;
  nextPlanName?: string;
  subscriptionInfo: SubscriptionInfo;
  subscription: Subscription;
  stripeSubscription?: StripeSubscription;
  updateSubscription: (siteId: number, subscription: SubscriptionPayload) => Promise<Subscription>;
  refreshInvoicesAndStripeSubscription: () => Promise<any>;
};

const Cancellation = ({
  cancelSubscriptionWithMessage,
  currentPlanName,
  nextPlanName,
  subscriptionInfo,
  subscription,
  updateSubscription,
  stripeSubscription,
  refreshInvoicesAndStripeSubscription,
}: CancellationProps) => {
  const siteUrl = useSiteUrl();
  const [isCancelConfirmVisible, setIsCancelConfirmVisible] = useState(false);
  const isInTrial = !subscription.plan;
  const hasUpcomingPlan = !!subscription.next_plan;

  // We need to give 2 hours for Stripe to process payments
  const isPaymentDue =
    differenceInHours(
      new Date(),
      // Make sure that we compare to the time when payment is due
      subscription.valid_until ? new Date(`${subscription.valid_until}T23:59:00.000Z`) : new Date()
    ) >= 3;

  if (!subscription) {
    return null;
  }

  if (isPaymentDue) {
    return (
      <>
        <Heading level="h2" type="secondary" id="cancellation">
          Cancellation
        </Heading>
        <div className={styles.cancellationDescription}>
          <p>
            You have an active subscription with{' '}
            <strong className="capitalize">{nextPlanName || subscription.next_plan} plan</strong> to Flockler. You'll
            need to pay the pending invoice(s) before you can cancel your subscription. Please{' '}
            <Link to={pathToSettingsSubscriptionEditPaymentDetails(siteUrl)} className="font-semibold">
              update your {stripeSubscription?.payment_method === 'sepa_debit' ? 'SEPA mandate' : 'card details'}
            </Link>{' '}
            or <HelpScoutLink className="font-semibold">contact the support</HelpScoutLink> to proceed with the
            cancellation. After the subscription ends, the collected content can no longer be presented on your website.
            All data will also be deleted 90 days after the end of the subscription.
          </p>
        </div>
      </>
    );
  }

  if (isInTrial && hasUpcomingPlan) {
    return (
      <>
        <Heading level="h2" type="secondary" id="cancellation">
          Cancellation
        </Heading>
        <div className={styles.cancellationDescription}>
          <p>
            You are currently in a trial with{' '}
            <strong className="capitalize">{nextPlanName || subscription.next_plan} plan</strong> selected. If you
            cancel the subscription choice, the current plan can be used until{' '}
            <strong>{format(new Date(subscription.valid_until as string), 'MMM dd, yyyy')}</strong>. After the trial
            ends, the collected content can no longer be presented on your website. All data will also be deleted 90
            days after the end of the subscription.
          </p>
        </div>
        <Button linkLike variant="danger" action={() => setIsCancelConfirmVisible(true)}>
          Cancel subscription
        </Button>
        {isCancelConfirmVisible && (
          <CancellationConfirmModal
            onCancel={() => setIsCancelConfirmVisible(false)}
            onConfirm={(message: string) => {
              return cancelSubscriptionWithMessage(message).then(() => setIsCancelConfirmVisible(false));
            }}
            subscription={subscription}
          />
        )}
      </>
    );
  }

  if (!subscriptionInfo.willBeUnsubscribed) {
    return (
      <>
        <Heading level="h2" type="secondary" id="cancellation">
          Cancellation
        </Heading>

        {!subscriptionInfo.willBeUnsubscribed && (
          <>
            <div className={styles.cancellationDescription}>
              <p>
                {subscription.state === 'trial_subscribed' ? (
                  <>
                    Your free trial changes to a paid subscription with{' '}
                    <strong className="capitalize">{currentPlanName || subscription.plan} plan</strong> on{' '}
                    {format(new Date(subscription.valid_until as string), 'MMM dd, yyyy')}. If you choose to cancel the
                    subscription, your Flockler plan can be used until{' '}
                    <strong>{format(new Date(subscription.valid_until as string), 'MMM dd, yyyy')}</strong>. When the
                    trial ends and you don’t have an active subscription, the collected content can no longer be
                    presented on your website. All data will also be deleted 90 days after the end of the subscription.
                  </>
                ) : (
                  <>
                    You have an active subscription with{' '}
                    <strong className="capitalize">{currentPlanName || subscription.plan} plan</strong> to Flockler. If
                    you choose to cancel the subscription, the current plan can be used until{' '}
                    <strong>{format(new Date(subscription.valid_until as string), 'MMM dd, yyyy')}</strong>. After the
                    subscription ends, the collected content can no longer be presented on your website. All data will
                    also be deleted 90 days after the end of the subscription.
                  </>
                )}
              </p>
            </div>
            <Button linkLike variant="danger" action={() => setIsCancelConfirmVisible(true)}>
              Cancel subscription
            </Button>
            {isCancelConfirmVisible && (
              <CancellationConfirmModal
                onCancel={() => setIsCancelConfirmVisible(false)}
                onConfirm={(message: string) => {
                  return cancelSubscriptionWithMessage(message).then(() => setIsCancelConfirmVisible(false));
                }}
                subscription={subscription}
              />
            )}
          </>
        )}
      </>
    );
  }

  return (
    <>
      <Heading level="h2" type="secondary" id="cancellation">
        Cancellation
      </Heading>

      <div className={styles.cancellationDescription}>
        <p>
          You have unsubscribed the current <strong className="capitalize">{currentPlanName} plan</strong> to Flockler.
          The plan can still be used until{' '}
          <strong>{format(new Date(subscription.valid_until as string), 'MMM dd, yyyy')}</strong>. When the subscription
          ends, the collected content can no longer be presented on your website. All data will also be deleted 90 days
          after the end of the subscription.
        </p>
      </div>
      <Button
        variant="success"
        action={() => {
          return updateSubscription(subscription.site_id, { next_plan: subscription.plan }).then(
            refreshInvoicesAndStripeSubscription
          );
        }}
      >
        Resubscribe to <span className="capitalize">{currentPlanName || subscription.plan}</span> plan
      </Button>
    </>
  );
};

export default Cancellation;
