import React, { useState } from 'react';
import Button from 'components/Button';
import Modal from 'components/Modal';
import { DEFAULT_FORM_CONFIG, FORM_FIELD_LABELS } from 'services/reviews';

import ReviewFormLabelRichTextEditor from '../ReviewFormLabelRichTextEditor';

const ReviewSidepanelBlockEditModal = ({
  fieldId,
  field,
  text,
  cancelAction,
  confirmAction,
}: {
  fieldId: ReviewFormFieldIdentifier;
  field: ReviewFormFieldDetails;
  text: ReviewFormConfigText;
  cancelAction: () => void;
  confirmAction: (fieldId: ReviewFormFieldIdentifier, fieldDetails: ReviewFormFieldDetails) => void;
}) => {
  const [fieldDetails, setFieldDetails] = useState({ ...field });

  const handleLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newLabel = e.target.value.length > 0 ? e.target.value : FORM_FIELD_LABELS[fieldId];
    setFieldDetails({
      ...fieldDetails,
      label: newLabel,
    });
  };

  const handleHelpTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setFieldDetails({
      ...fieldDetails,
      helpText: value ? value : undefined,
    });
  };

  const handleRequiredChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFieldDetails({
      ...fieldDetails,
      required: e.target.value === 'required',
    });
  };

  const handleVisibilityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldDetails({
      ...fieldDetails,
      visibility: e.target.value,
    });
  };

  const handleCustomFieldTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldDetails({
      ...fieldDetails,
      type: e.target.value as 'text' | 'textarea',
    });
  };

  let labelName = field.label;
  if (fieldId === 'terms') labelName = 'Terms and conditions';
  if (fieldId === 'checkbox') labelName = 'Custom checkbox';

  return (
    <Modal
      title={`Edit field: ${labelName}`}
      actionButtons={[
        <Button key="cancel" variant="neutral" size="small" action={cancelAction}>
          Cancel
        </Button>,
        <Button key="success" variant="success" size="small" action={() => confirmAction(fieldId, fieldDetails)}>
          Save changes
        </Button>,
      ]}
      dismissAction={cancelAction}
    >
      <div className="my-5 space-y-5">
        <div className="grid grid-cols-3 gap-6">
          <div className="col-span-2">
            <label htmlFor="editFieldLabel" className="mb-2 min-h-0">
              Label of the field
            </label>
            {['terms', 'checkbox'].includes(fieldId) ? (
              <ReviewFormLabelRichTextEditor
                value={fieldDetails.label}
                onChange={(label: string) => setFieldDetails({ ...fieldDetails, label })}
              />
            ) : (
              <input
                id="editFieldLabel"
                type="text"
                defaultValue={fieldDetails.label}
                onChange={handleLabelChange}
                className={fieldDetails.label.length === 0 ? 'border border-red-600' : ''}
              />
            )}
          </div>
          <div>
            <label htmlFor="editFieldRequired" className="mb-2 min-h-0">
              Is this a required field?
            </label>
            <select
              id="editFieldRequired"
              defaultValue={fieldDetails.required ? 'required' : 'optional'}
              onChange={handleRequiredChange}
            >
              <option value="required">Required</option>
              <option value="optional">Optional</option>
            </select>
          </div>
        </div>

        {!['checkbox', 'file'].includes(field.type) && fieldId !== 'rating' && (
          <div>
            <label htmlFor="editFieldHelpText" className="mb-2 min-h-0">
              Optional help text
            </label>
            <input
              id="editFieldHelpText"
              type="text"
              defaultValue={fieldDetails.helpText}
              onChange={handleHelpTextChange}
            />
            <HelpText>The help text will be shown below the input field like this.</HelpText>
          </div>
        )}

        {fieldId === 'lastName' && (
          <div>
            <label htmlFor="editFieldHelpText" className="mb-2 min-h-0">
              How should the last name be shown publicly?
            </label>
            <div className="flex space-x-4">
              <label className="space-x-1 font-normal">
                <input
                  type="radio"
                  name="lastNameFieldVisibility"
                  value="visible"
                  checked={!fieldDetails.visibility || fieldDetails.visibility === 'visible'}
                  onChange={handleVisibilityChange}
                />
                <span>
                  Full name <span className="text-slate-600">(e.g., "Smith")</span>
                </span>
              </label>
              <label className="space-x-1 font-normal">
                <input
                  type="radio"
                  name="lastNameFieldVisibility"
                  value="truncated"
                  checked={fieldDetails.visibility === 'truncated'}
                  onChange={handleVisibilityChange}
                />
                <span>
                  First letter <span className="text-slate-500">(e.g., "S.")</span>
                </span>
              </label>
              <label className="space-x-1 font-normal">
                <input
                  type="radio"
                  name="lastNameFieldVisibility"
                  value="hidden"
                  checked={fieldDetails.visibility === 'hidden'}
                  onChange={handleVisibilityChange}
                />{' '}
                <span>Not visible publicly</span>
              </label>
            </div>
          </div>
        )}

        {fieldId === 'image' && (
          <div>
            <ImageHelpTextSetting
              label="Image upload instruction"
              updateValue={(value) =>
                setFieldDetails({
                  ...fieldDetails,
                  helpText: value ? value : undefined,
                })
              }
              value={fieldDetails.helpText}
              fallbackValue={text.imageInstruction}
            />
          </div>
        )}

        {['customFieldA', 'customFieldB', 'customFieldC', 'customFieldD', 'customFieldE'].includes(fieldId) && (
          <div className="grid grid-cols-2 gap-6">
            <div>
              <label htmlFor="editFieldHelpText" className="mb-1 min-h-0">
                Input type
              </label>
              <div className="flex space-x-4">
                <label className="space-x-1 font-normal">
                  <input
                    type="radio"
                    name="customFieldType"
                    value="text"
                    checked={fieldDetails.type === 'text'}
                    onChange={handleCustomFieldTypeChange}
                  />
                  <span>Line of text</span>
                </label>
                <label className="space-x-1 font-normal">
                  <input
                    type="radio"
                    name="customFieldType"
                    value="textarea"
                    checked={fieldDetails.type === 'textarea'}
                    onChange={handleCustomFieldTypeChange}
                  />
                  <span>Multiple lines of text</span>
                </label>
              </div>
            </div>
            <div>
              <label htmlFor="editFieldHelpText" className="mb-1 min-h-0">
                Visibility in displays
              </label>
              <div className="flex space-x-4">
                <label className="space-x-1 font-normal">
                  <input
                    type="radio"
                    name="customFieldVisibility"
                    value="visible"
                    checked={!fieldDetails.visibility || fieldDetails.visibility === 'visible'}
                    onChange={handleVisibilityChange}
                  />
                  <span>Publicly visible</span>
                </label>
                <label className="space-x-1 font-normal">
                  <input
                    type="radio"
                    name="customFieldVisibility"
                    value="hidden"
                    checked={fieldDetails.visibility === 'hidden'}
                    onChange={handleVisibilityChange}
                  />{' '}
                  <span>Private</span>
                </label>
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

ReviewSidepanelBlockEditModal.displayName = 'ReviewSidepanelBlockEditModal';
export default ReviewSidepanelBlockEditModal;

const HelpText = ({ children, className }: { children: React.ReactNode; className?: string }) => (
  <small className={`flex items-center space-x-1 text-slate-700 ${className}`}>{children}</small>
);

const ImageHelpTextSetting = ({
  label,
  updateValue,
  value,
  fallbackValue,
}: {
  label: string;
  updateValue: (value: string) => void;
  value: string | undefined;
  fallbackValue: string | undefined;
}) => {
  const defaultValue = DEFAULT_FORM_CONFIG.config.text.imageInstruction;
  return (
    <div>
      <label className="mb-2 min-h-0" htmlFor="editFieldHelpText">
        {label}
      </label>
      <input
        type="text"
        id="editFieldHelpText"
        defaultValue={value || fallbackValue}
        placeholder={defaultValue}
        value={value}
        onChange={(e) => updateValue(e.target.value)}
        onBlur={(e) => {
          if (!e.currentTarget.value) updateValue(defaultValue);
        }}
        className="!h-10 !text-sm"
      />
    </div>
  );
};
