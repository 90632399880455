import React from 'react';
import Button from 'components/Button';

import styles from './CopyButton.module.scss';
import Icon from 'components/Icon';

type CopyButtonProps = {
  children?: any;
  className?: any;
  copiedMessage?: string;
  isCopiedMessageVisible: boolean;
  size?: 'tiny' | 'small' | 'medium';
  style?: any;
  onClick: any;
};

export function CopyButton({
  children = 'Copy',
  className,
  copiedMessage = 'Copied to clipboard',
  isCopiedMessageVisible,
  onClick,
  size,
  style,
  ...rest
}: CopyButtonProps) {
  return (
    <Button
      action={onClick}
      className={className}
      variant={isCopiedMessageVisible ? 'success' : 'primary'}
      size={size}
      style={{ ...style, outline: 0 }}
      {...rest}
    >
      {isCopiedMessageVisible ? (
        <span className={styles.copyMessage}>
          <Icon type="checkmark" className={styles.icon} />
          {copiedMessage}
        </span>
      ) : (
        children
      )}
    </Button>
  );
}

export default CopyButton;
