import { useState } from 'react';
import config from 'config';

import Button from 'components/Button';

const ReviewFormsListItemEmbedCodeCopier = ({ form }: { form: ReviewForm }) => {
  const [isCopiedMessageVisible, setCopiedMessageVisible] = useState(false);

  const embedCode = `
<div class="flockler-review-form" data-site-uuid="${form.siteUuid}" data-form-uuid="${form.uuid}"></div>
<script src="${config.flocklerPluginsUrl}/reviews/embed.js" async></script>
`.trim();

  return (
    <div className="relative h-16 w-full overflow-hidden rounded-lg bg-slate-50 text-sm">
      <div
        className="absolute bottom-0 left-0 top-0 flex items-center"
        onClick={(e) => {
          const range = document.createRange();
          range.selectNode(e.currentTarget);
          window.getSelection()?.removeAllRanges();
          window.getSelection()?.addRange(range);
        }}
      >
        <pre
          className="h-10 whitespace-pre px-3 text-xs leading-5 text-slate-800"
          style={{ fontFamily: 'Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace' }}
        >
          {embedCode}
        </pre>
      </div>
      <div className="absolute bottom-0 right-0 top-0 z-10 flex w-2/3 items-center justify-end bg-gradient-to-l from-slate-50 via-slate-50 pl-6 pr-2">
        <Button
          variant="primary"
          action={() => {
            setCopiedMessageVisible(true);
            navigator.clipboard.writeText(embedCode).then(
              function () {
                setTimeout(() => {
                  setCopiedMessageVisible(false);
                }, 1500);
              },
              function () {
                setCopiedMessageVisible(false);
              }
            );
          }}
          className="md:w-48"
        >
          {isCopiedMessageVisible ? 'Copied!' : 'Copy embed code'}
        </Button>
      </div>
    </div>
  );
};

ReviewFormsListItemEmbedCodeCopier.displayName = 'ReviewFormsListItemEmbedCodeCopier';
export default ReviewFormsListItemEmbedCodeCopier;
