import { useState, useEffect } from 'react';
import without from 'lodash/without';
import { ConnectedProps, connect } from 'react-redux';
import classnames from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import { RootState } from 'redux/store';
import { useFormik } from 'formik';
import { Helmet } from 'react-helmet';
import useSections from 'hooks/api/useSections';
import useQuery from 'hooks/useQuery';

import { getCurrentSite, updateSite } from 'concepts/site';
import { getSubscription, isActive, fetchSubscription } from 'concepts/subscription';
import { getFeedIdsToKeep, setFeedIdsToKeep } from 'concepts/subscription-create';
import { fetchPlans, getAvailablePlans } from 'concepts/plan';
import {
  pathToSettingsSubscriptionPlanSelect,
  pathToSettingsSubscriptionDetails,
  pathToSettingsSubscriptionOverview,
  pathToSettingsSubscriptionDetailsWithBankTransfer,
} from 'services/routes';

import { fetchMediaTrackers, getEnabledMediaTrackers } from 'concepts/media-tracker';
import styles from './SubscriptionManageFeeds.module.scss';
import Button from 'components/Button';
import waitToBeReady from 'components/WaitToBeReadyHOC';
import AutomatedFeedsListItemIcon from 'pages/automated-feeds/components/AutomatedFeedsList/AutomatedFeedsListItemIcon';
import { getFeedName } from 'pages/automated-feeds/components/AutomatedFeedsList/AutomatedFeedsListItem';

type SubscriptionPlanSelectProps = ConnectedProps<typeof connector>;

const SubscriptionManageFeeds = ({
  availablePlans,
  fetchPlans,
  fetchSubscription,
  fetchMediaTrackers,
  enabledMediaTrackers,
  setFeedIdsToKeep,
  storedFeedIds,
  site,
  subscription,
}: SubscriptionPlanSelectProps) => {
  const history = useHistory();
  const query = useQuery();
  const { sections } = useSections(site.id, { pageSize: null });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetchPlans(site.id);
    fetchMediaTrackers();
    fetchSubscription(site.id);
  }, [site.id, fetchPlans, fetchSubscription, fetchMediaTrackers]);

  const litePlanKey = ['lite', site.currency?.toLowerCase()].filter((value) => !!value).join('_');
  const maxFeedCountForPlan = availablePlans.plans?.find((plan) => plan.id === litePlanKey)?.max_media_trackers;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      // User might have paused/removed feeds in another tab
      feedIds: storedFeedIds?.filter((id) => enabledMediaTrackers?.find((mt) => mt.id === id)) || [],
    },
    onSubmit: (values) => {
      setIsSubmitting(true);
      const planId = query.get('plan') as string;
      const billingType = query.get('billingType');
      const { feedIds } = values;

      setFeedIdsToKeep(feedIds);

      switch (billingType) {
        case 'bankTransfer':
          return history.push(pathToSettingsSubscriptionDetailsWithBankTransfer(site.site_url, planId));
        case 'sepaDebit':
          return history.push(pathToSettingsSubscriptionDetails(site.site_url, planId, 'sepa-debit'));
        default:
          return history.push(pathToSettingsSubscriptionDetails(site.site_url, planId, 'card'));
      }
    },
  });

  // Prevent navigation from active subscriptions
  if (isActive(subscription)) {
    history.replace(pathToSettingsSubscriptionOverview(site.site_url));
  }

  const onFeedSelect = (target: any) => {
    const { checked, value } = target;

    const selectedId = parseInt(value, 10);

    // Toggle off
    if (!checked) {
      const updatedSelectedFeeds = without(formik.values.feedIds, selectedId);
      formik.setFieldValue('feedIds', updatedSelectedFeeds);
      return;
    }

    // Toggle on
    formik.setFieldValue('feedIds', [...(formik.values.feedIds || []), selectedId]);
  };

  const selectedCount = formik.values.feedIds?.length || 0;

  return (
    <>
      <Helmet>
        <title>Flockler {'\u203A'} Manage your Automated Feeds</title>
      </Helmet>
      <form onSubmit={formik.handleSubmit} className={styles.SubscriptionManageFeeds}>
        <div className={styles.SubscriptionManageFeedsContent}>
          <div className={styles.section}>
            <div className={styles.subTitle}>Manage your Automated Feeds</div>
            <p className="mb-4 text-md">
              Lite plan includes {maxFeedCountForPlan} auto-updating sources. You have currently{' '}
              {enabledMediaTrackers?.length} auto-updating sources. Choose which ones you want to keep collecting fresh
              content. If you want to keep all your sources active, please{' '}
              <Link className="underline" to={pathToSettingsSubscriptionPlanSelect(site.site_url)}>
                choose a bigger plan
              </Link>
              .
            </p>

            <div className={styles.feedOptions}>
              {enabledMediaTrackers?.map((feed) => (
                <label
                  className={classnames(styles.feedOption, {
                    [styles.selected]: formik.values.feedIds?.includes(feed.id),
                  })}
                  key={feed.id}
                >
                  <input
                    type="checkbox"
                    name={`${feed.id}`}
                    value={feed.id}
                    checked={formik.values.feedIds?.includes(feed.id)}
                    onChange={(e) => onFeedSelect(e.target)}
                  />
                  <span className={styles.checkboxReplacement} />
                  <div className={styles.content}>
                    <AutomatedFeedsListItemIcon service={feed.service} />
                    <div className={styles.info}>
                      <span className={styles.name}>{getFeedName(feed)}</span>
                      <span className={styles.description}>
                        Section:{' '}
                        {sections.find((s) => s.id === feed.media_tracker_subscriptions?.[0]?.subscriber_id)?.name}
                      </span>
                    </div>
                  </div>
                </label>
              ))}
            </div>
          </div>

          {/* Error message */}
          <div className="center mt-4 h-12 text-sm font-medium text-slate-400">
            {/* Too many */}
            {maxFeedCountForPlan && selectedCount > maxFeedCountForPlan && (
              <span className="inline-block animate-fade-in text-red-600">
                Lite plan includes {maxFeedCountForPlan} auto-updating sources. Choose less sources or{' '}
                <Link className="text-red-600 underline" to={pathToSettingsSubscriptionPlanSelect(site.site_url)}>
                  change to a bigger plan
                </Link>
                .
              </span>
            )}

            {/* None selected */}
            {maxFeedCountForPlan && selectedCount <= maxFeedCountForPlan && (
              <span>
                Select {maxFeedCountForPlan} sources that will keep collecting fresh content, others will be paused.
              </span>
            )}
          </div>

          {/* Submit */}
          <div className={styles.SubscriptionActions}>
            <Button
              className={styles.submitButton}
              variant="success"
              size="large"
              type="submit"
              disabled={
                (maxFeedCountForPlan ? formik.values.feedIds?.length !== maxFeedCountForPlan : false) || isSubmitting
              }
            >
              {isSubmitting ? 'Saving settings…' : 'Continue'}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

// Wrap with HOC to show spinner until props are loaded
const SubscriptionManageFeedsReady = waitToBeReady(SubscriptionManageFeeds, ['site', 'subscription', 'availablePlans']);

const mapStateToProps = (state: RootState) => ({
  availablePlans: getAvailablePlans(state),
  storedFeedIds: getFeedIdsToKeep(state),
  enabledMediaTrackers: getEnabledMediaTrackers(state),
  site: getCurrentSite(state),
  subscription: getSubscription(state),
});

const mapDispatchToProps = {
  fetchPlans,
  fetchSubscription,
  fetchMediaTrackers,
  setFeedIdsToKeep,
  updateSite,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(SubscriptionManageFeedsReady);
