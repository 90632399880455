import { useState, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import { getSubscription, updateSubscription, Subscription } from 'concepts/subscription';
import { fetchStripeSubscription, getStripeSubscription, StripeSubscription } from 'concepts/stripe-subscription';
import { fetchVat } from 'services/api';
import {
  preCheckVat,
  VatErrorKey,
  vatErrorLabels,
  vatPrefixForCountryCode,
  EU_MEMBER_COUNTRIES_WITH_VAT_ID,
} from 'services/vat';
import { getCountries } from 'concepts/country';
import useCurrentSite from 'hooks/useCurrentSite';
import { pathToSettingsSubscriptionOverview } from 'services/routes';
import HelpScoutLink from 'components/HelpScoutLink';
import { Formik, Field } from 'formik';
import NoticeBox from 'components/NoticeBox';
import Modal from 'components/Modal';
import Button from 'components/Button';
import styles from './SubscriptionEditOrganizationDetailsModal.module.scss';
import { useHistory } from 'react-router-dom';

type SubscriptionEditOrganizationDetailsModalProps = {
  countries: Country[];
  stripeSubscription: StripeSubscription;
  subscription: Subscription;
  fetchStripeSubscription: (siteId: number) => void;
  updateSubscription: (siteId: number, subscription: any) => Promise<Subscription>;
};

const SubscriptionEditOrganizationDetailsModal = ({
  countries,
  fetchStripeSubscription,
  stripeSubscription,
  subscription,
  updateSubscription,
}: SubscriptionEditOrganizationDetailsModalProps) => {
  const history = useHistory();

  const [isValidVatId, setIsValidVatId] = useState(true);
  const [hasValidatedVatIdInitially, setHasValidatedVatIdInitially] = useState(false);
  const [vatError, setVatError] = useState<VatErrorKey | undefined>(undefined);

  const [vatRate, setVatRate] = useState<number | undefined>(undefined);
  const [isUpdatingSubscription, setIsUpdatingSubscription] = useState(false);
  const [isValidatingVatId, setIsValidatingVatId] = useState(false);
  const [hasUpdateError, setHasUpdateError] = useState(false);
  const isLoadingUser = false;

  const site = useCurrentSite();
  const dismissAction = () => history.replace(pathToSettingsSubscriptionOverview(site.site_url));

  const updateVatRate = useCallback(
    (countryCode: string, vatId: string) => {
      const selectedCountry = countries.find((c) => c.code === countryCode);
      setVatError(undefined);
      setIsValidVatId(true);

      if (!selectedCountry) {
        setVatError('country_missing');
        return;
      }

      // If selected country doesn't need vat, everything is OK
      if (!selectedCountry?.vat) {
        return;
      }

      return preCheckVat(selectedCountry, vatId)
        .then(() => setIsValidatingVatId(true))
        .then(() => fetchVat(countryCode, vatId))
        .then((response: AxiosApiResponse) => {
          setVatRate(response.data.vat_rate);
          setIsValidVatId(!vatId || !selectedCountry.vat || response.data.valid_vat_id);
          setIsValidatingVatId(false);
        })
        .catch(setVatError);
    },
    [countries]
  );

  const debouncedUpdateVatRate = debounce(updateVatRate, 500);

  // This checks on first render whether the given VAT-ID is still valid
  useEffect(() => {
    if (hasValidatedVatIdInitially) return;
    setHasValidatedVatIdInitially(true);

    const countryCode = subscription.company.country || '';
    const vatId = subscription.vat_id;
    if (!vatId) return;

    updateVatRate(countryCode, vatId);
  }, [subscription, updateVatRate, hasValidatedVatIdInitially, setHasValidatedVatIdInitially]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        organizationName: subscription.company.name || '',
        streetAddress: subscription.company.street_address || '',
        postcode: subscription.company.postcode || '',
        city: subscription.company.city || '',
        country: subscription.company.country || '',

        email: subscription.email || '',
        vatId: subscription.vat_id || '',
      }}
      onSubmit={(values) => {
        // Prevent multiple/pending/invalid submits
        if (isUpdatingSubscription || isValidatingVatId || !isValidVatId || !!vatError) {
          return;
        }

        setIsUpdatingSubscription(true);
        setHasUpdateError(false);

        return updateSubscription(site.id, {
          email: values.email,
          site_id: site.id,
          vat_id: values.vatId,
          company: {
            street_address: values.streetAddress,
            postcode: values.postcode,
            city: values.city,
            country: values.country,
            name: values.organizationName,
          },
        })
          .then(() => fetchStripeSubscription(site.id))
          .then(() => {
            setIsUpdatingSubscription(false);
            dismissAction();
          })
          .catch(() => {
            setIsUpdatingSubscription(false);
            setHasUpdateError(true);
          });
      }}
      validate={(values) => {
        const errors = {} as any;

        // Company is required
        if (!values.organizationName) {
          errors.organizationName = 'Please enter a company.';
        }

        if (!values.country) {
          errors.country = 'Please select a country.';
        }

        if (!values.city) {
          errors.city = 'Please enter a city.';
        }

        if (!values.postcode) {
          errors.postcode = 'Please enter a postcode.';
        }

        if (!values.email) {
          errors.email = 'Please enter an email address.';
        } else if (values.email.indexOf('@') < 0) {
          errors.email = 'Please enter a valid email address.';
        }

        if (!values.streetAddress) {
          errors.streetAddress = 'Please enter a street address.';
        }

        return errors;
      }}
    >
      {({ handleSubmit, handleChange, values, errors, touched }) => {
        const selectedCountry = countries.find((c) => c.code === values.country);
        const hasErrors = !isEmpty(errors);

        return (
          <form
            onSubmit={(e) => {
              if (!hasErrors) {
                handleSubmit(e);
              }
            }}
          >
            <Modal
              title="Organization’s details"
              actionButtons={[
                <Button
                  key="subscriptionOrganizationUpdateCancel"
                  variant="neutral"
                  size="medium"
                  disabled={isUpdatingSubscription}
                  action={dismissAction}
                >
                  Cancel
                </Button>,
                <Button
                  key="subscriptionOrganizationUpdateSubmit"
                  variant="success"
                  size="medium"
                  type="submit"
                  action={() => handleSubmit()}
                  disabled={isValidatingVatId || !isValidVatId || !!vatError || hasErrors || isUpdatingSubscription}
                >
                  {isUpdatingSubscription ? 'Saving changes…' : 'Save changes'}
                </Button>,
              ]}
              dismissAction={dismissAction}
            >
              <section className={styles.SubscriptionSection}>
                <div className="grid gap-3 sm:grid-cols-3">
                  <div
                    className={classnames('sm:col-span-3', {
                      [styles.errorField]: errors?.organizationName,
                    })}
                  >
                    <label className="label" htmlFor="organizationName">
                      <span>Organization name</span>
                    </label>
                    <Field
                      autoComplete="organization"
                      id="organizationName"
                      name="organizationName"
                      type="text"
                      required
                    />

                    {errors?.organizationName && <div className={styles.formError}>{errors.organizationName}</div>}
                  </div>

                  <div
                    className={classnames('sm:col-span-3', {
                      [styles.errorField]: errors?.streetAddress,
                    })}
                  >
                    <label className="label" htmlFor="streetAddress">
                      Street address
                    </label>
                    <Field autoComplete="street-address" id="streetAddress" name="streetAddress" type="text" required />

                    {errors?.streetAddress && <div className={styles.formError}>{errors.streetAddress}</div>}
                  </div>

                  <div
                    className={classnames('', {
                      [styles.errorField]: errors?.postcode,
                    })}
                  >
                    <label className="label" htmlFor="postcode">
                      Postcode
                    </label>
                    <Field autoComplete="postal-code" id="postcode" name="postcode" type="text" required />

                    {errors?.postcode && <div className={styles.formError}>{errors.postcode}</div>}
                  </div>

                  <div
                    className={classnames('sm:col-span-2', {
                      [styles.errorField]: errors?.city,
                    })}
                  >
                    <label className="label" htmlFor="city">
                      City
                    </label>
                    <Field autoComplete="address-level2" id="city" name="city" type="text" required />

                    {errors?.city && <div className={styles.formError}>{errors.city}</div>}
                  </div>

                  <div
                    className={classnames('sm:col-span-3', {
                      [styles.errorField]: errors?.country,
                    })}
                  >
                    <label className="label" htmlFor="country">
                      Country
                    </label>
                    <select
                      autoComplete="country"
                      id="country"
                      name="country"
                      value={values.country}
                      onChange={(event: any) => {
                        debouncedUpdateVatRate(event.target.value, values.vatId);
                        handleChange(event);
                      }}
                      required
                    >
                      {!values.country && <option value="">Please select a country…</option>}
                      {sortBy(countries, 'name').map((country: Country) => (
                        <option key={country.code} value={country.code}>
                          {country.name}
                        </option>
                      ))}
                    </select>

                    {errors?.country && <div className={styles.formError}>{errors.country}</div>}
                  </div>
                </div>

                {EU_MEMBER_COUNTRIES_WITH_VAT_ID.includes(values.country) && (
                  <>
                    <hr className={styles.divider} />

                    <div className={classnames(styles.FormFieldContext, 'mt-2')}>
                      <div className={classnames('sm:col-span-3', { [styles.errorField]: !isValidVatId || vatError })}>
                        <label className="label justify-start" htmlFor="vatId">
                          VAT ID number
                          <div className="label__context ml-2">
                            ({values.country === 'US' ? 'Optional' : 'Optional, adds reverse charge statement to your invoice'})
                          </div>
                        </label>
                        <div className="label__context mb-2">
                          Expected format: {vatPrefixForCountryCode(values.country)}12345678
                        </div>
                        <input
                          id="vatId"
                          name="vatId"
                          value={values.vatId}
                          onChange={(event: any) => {
                            debouncedUpdateVatRate(values.country, event.target.value);
                            handleChange(event);
                          }}
                          type="text"
                        />
                      </div>

                      {vatError && (
                        <div className="sm:col-span-3">
                          <p className={styles.formError}>{vatErrorLabels[vatError]}</p>
                        </div>
                      )}
                    </div>

                    {selectedCountry?.vat && (
                      <>
                        {isValidatingVatId && <NoticeBox type="neutral">Validating VAT ID…</NoticeBox>}

                        {!isValidatingVatId && !values.vatId && (
                          <NoticeBox type="neutral" withIcon="notice">
                            If your organization is a registered business in European Union, please enter your
                            organization’s{' '}
                            <a
                              className={styles.plainLink}
                              href="https://en.wikipedia.org/wiki/VAT_identification_number"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              VAT ID number
                            </a>{' '}
                            for the correct tax rate or reverse charge statement.
                          </NoticeBox>
                        )}

                        {!isValidatingVatId && values.vatId && !isValidVatId && (
                          <NoticeBox type="danger" withIcon>
                            We could not recognize this as a valid VAT ID number. Please type your VAT ID like this:{' '}
                            {vatPrefixForCountryCode(values.country)}12345678 and make sure your VAT ID is active.
                          </NoticeBox>
                        )}

                        {!isValidatingVatId && !!values.vatId && !vatError && isValidVatId && (
                          <NoticeBox type="success" withIcon>
                            VAT number is valid
                          </NoticeBox>
                        )}
                      </>
                    )}
                  </>
                )}

                <hr className={styles.divider} />

                <div className={classnames('sm:col-span-3', { [styles.errorField]: errors.email && touched.email })}>
                  <label className="label" htmlFor="email">
                    Email address
                  </label>
                  <div className="label__context mb-2">
                    Receipts and notifications will be sent to this email address
                  </div>

                  <Field autoComplete="email" disabled={isLoadingUser} id="email" name="email" type="email" required />
                  {errors.email && touched.email && <div className={styles.formError}>{errors.email}</div>}
                </div>

                {hasUpdateError && (
                  <div className={styles.formError}>
                    Saving organization’s details failed. Please{' '}
                    <HelpScoutLink messageText="I tried to update organization’s details, but it failed. Can you help?">
                      contact us
                    </HelpScoutLink>
                    .
                  </div>
                )}
              </section>
            </Modal>
          </form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => ({
  countries: getCountries(state),
  stripeSubscription: getStripeSubscription(state),
  subscription: getSubscription(state),
});

const mapDispatchToProps = {
  fetchStripeSubscription,
  updateSubscription,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionEditOrganizationDetailsModal);
