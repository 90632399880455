import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { getSiteSettings } from 'concepts/site';
import { useAppSelector } from 'hooks/useAppSelector';
import useCurrentSite from 'hooks/useCurrentSite';

import Page from 'components/Page';
import Button from 'components/Button';
import PageLoader from 'components/Loader/PageLoader';
import { withSiteLoaded } from 'components/WithSiteLoaded';

import ProductIllustration from 'images/commerce/product-popup.png';
import EcommerceImage from 'images/commerce/ecommerce.png';
import ProductTagGif from 'images/commerce/product-tag.gif';

import styles from './CommerceLandingPage.module.scss';
import config from 'config';
import classNames from 'classnames';
import useSiteId from 'hooks/useSiteId';
import * as api from 'services/api';
import AnimateFadeIn from 'components/AnimateFadeIn';
import { sleep } from 'services/async';

const _CommerceLandingPage = () => {
  const site = useCurrentSite();
  const [status, setStatus] = useState<'INITIAL' | 'LOADING' | 'SUCCESS'>('INITIAL');
  const siteId = useSiteId();

  const settings = useAppSelector(getSiteSettings);

  const navigateToCommerceApp = useCallback(() => {
    window.location.href = `${config.commerceAppUrl}/${site.site_url}`;
  }, [site?.site_url]);

  useEffect(() => {
    if (settings?.commerce_enabled && site?.site_url) {
      navigateToCommerceApp();
    }
  }, [settings?.commerce_enabled, site?.site_url, navigateToCommerceApp]);

  const enableCommerce = async () => {
    setStatus('LOADING');

    try {
      await Promise.all([sleep(1000), await api.requestCommerceAccess(siteId)]);

      setStatus('SUCCESS');

      window.setTimeout(() => {
        navigateToCommerceApp();
      }, 3000);
    } catch (e) {
      setStatus('INITIAL');
    }
  };

  if (!settings || settings?.commerce_enabled) {
    return <PageLoader />;
  }

  const successScreen = (
    <div className="absolute left-0 top-0 z-10 flex h-full w-full flex-auto animate-fade-in flex-col items-center justify-center bg-white px-4 py-6 text-slate-800 md:px-6 lg:px-8 lg:py-10">
      <h1 className="mb-4 text-2xl font-bold">Great! 🙌</h1>
      <AnimateFadeIn animationDelay="500ms">
        <p className={classNames(styles.loadingDots, 'mb-8 text-center text-wrap-balance')}>
          You will be redirected to the Commerce add-on in a few seconds
        </p>
      </AnimateFadeIn>
    </div>
  );

  const ctaButton = (
    <Button disabled={status !== 'INITIAL'} variant="success" size="large" type="button" onClick={enableCommerce}>
      {status !== 'INITIAL' ? 'Setting things up...' : 'Let’s get started'}
    </Button>
  );

  return (
    <Page className={styles.analyticsPage}>
      <Helmet>
        <title>Flockler {'\u203A'} Commerce Add-on</title>
      </Helmet>

      {!site ? (
        <PageLoader />
      ) : (
        <>
          <h1 className={styles.mainHeading}>Make your posts shoppable with the Commerce add-on!</h1>
          <section className={styles.content}>
            <div className={classNames(styles.textCol, 'relative')}>
              <p className={styles.text}>
                Turn your branded content and UGC to shoppable galleries on websites and mobile apps. Tag your images
                and videos with products and add social proof to any digital platform.
              </p>

              <div className={styles.contentActions}>{ctaButton}</div>

              {status === 'SUCCESS' && successScreen}
            </div>
            <div className={styles.visualizationCol}>
              <figure className={styles.visualization}>
                <img src={ProductIllustration} alt="Commerce Add-on - Tagged product" />
              </figure>
            </div>
          </section>

          <div className={styles.contentBlocks}>
            <section className={styles.content}>
              <div className={styles.textCol}>
                <h2 className={styles.heading}>Integration with any webshop platform</h2>
                <p className={styles.text}>
                  Add your product feed to Flockler and keep your product information up-to-date without manual work.
                  Flockler makes sure your shoppable galleries don’t direct users to products that no longer exist in
                  your webshop.
                </p>
              </div>
              <div className={styles.visualizationCol}>
                <figure className={styles.visualization}>
                  <img
                    alt="Branded content and UGC to shoppable galleries"
                    loading="lazy"
                    src={EcommerceImage}
                    style={{ backgroundColor: '#f9fbfc' }}
                  />
                </figure>
              </div>
            </section>

            <section className={styles.content}>
              <div className={styles.textCol}>
                <h2 className={styles.heading}>Embed shoppable galleries anywhere</h2>
                <p className={styles.text}>
                  Create an unlimited number of Walls, Grids, and Carousels and embed them on any digital service.
                  Display shoppable galleries on the homepage, product pages, categories, and more.
                </p>
              </div>
              <div className={styles.visualizationCol}>
                <figure className={styles.visualization}>
                  <img alt="Branded content and UGC to shoppable galleries" loading="lazy" src={ProductTagGif} />
                </figure>
              </div>
            </section>

            <section className={styles.content}>
              <div className={styles.textCol}>
                <h2 className={styles.heading}>Social Proof drives purchases</h2>
                <p className={styles.text}>
                  Gather user-generated content from Instagram, Facebook, TikTok, and more, and display engaging feeds
                  on any digital platform. Embed shoppable galleries and social proof on your webshop to increase
                  credibility and conversion rates.
                </p>
              </div>
              <div className={styles.visualizationCol}>
                <figure className={styles.visualization}>
                  <img
                    alt="Branded content and UGC to shoppable galleries"
                    loading="lazy"
                    src="https://fl-1.cdn.flockler.com/assets/flockler2019/images/success-stories/case-metallica-bcf8be485112e95d0bdf55942f35e06973c4f39d0914849dbb9ddc39a6f79ef6.jpg"
                  />
                </figure>
              </div>
            </section>
          </div>

          <div className={classNames(styles.footerActions, 'relative')}>
            <h2 className={styles.footerHeading}>Make your branded content and UGC shoppable</h2>

            {ctaButton}

            {status === 'SUCCESS' && successScreen}
          </div>
        </>
      )}
    </Page>
  );
};

const CommerceLandingPage = withSiteLoaded(_CommerceLandingPage);

export default CommerceLandingPage;
