import Button from 'components/Button';
import Modal from 'components/Modal';

interface SectionsListItemDeleteModalProps {
  section: Section;
  entityName: string;
  deleteAction: () => void;
  dismissAction: () => void;
}

export const SectionsListItemDeleteModal = ({
  section,
  entityName,
  deleteAction,
  dismissAction,
}: SectionsListItemDeleteModalProps) => (
  <Modal
    title={`Delete ${entityName}?`}
    actionButtons={[
      <div key="cancel">
        <Button variant="neutral" size="small" action={dismissAction}>
          Cancel
        </Button>
      </div>,
      <div key="confirm">
        <Button variant="danger" size="small" action={deleteAction}>
          Delete
        </Button>
      </div>,
    ]}
    dismissAction={dismissAction}
  >
    {`Are you sure you want to delete ${entityName} “${section.name}”?`}
  </Modal>
);
