import React, { useState } from 'react';

import Button from 'components/Button';
import Modal from 'components/Modal';
import { SLIDESHOW } from 'constants/display-types';

type DisplayRemoveConfirmModalProps = {
  onRemove: any;
  setRemovingDisplay: (display: DisplayListItem | undefined) => void;
  removingDisplay?: DisplayListItem;
};

const DisplayRemoveConfirmModal = ({
  onRemove,
  removingDisplay,
  setRemovingDisplay,
}: DisplayRemoveConfirmModalProps) => {
  const [isRemoving, setRemoving] = useState(false);
  const dismiss = () => setRemovingDisplay(undefined);

  if (!removingDisplay) {
    return null;
  }

  return (
    <Modal
      title="Are you sure you want to remove this Display?"
      actionButtons={[
        <Button key="displayRemoveCancel" disabled={isRemoving} variant="neutral" size="small" action={dismiss}>
          Cancel
        </Button>,
        <Button
          key="displayRemoveConfirm"
          variant="danger"
          size="small"
          disabled={isRemoving}
          action={() => {
            setRemoving(true);

            onRemove(removingDisplay).then(() => {
              setRemoving(false);
              dismiss();
            });
          }}
        >
          {isRemoving ? 'Removing…' : 'Remove'}
        </Button>,
      ]}
      dismissAction={dismiss}
    >
      <>
        {removingDisplay.type === SLIDESHOW
          ? 'All screens displaying this Slideshow won’t show the content after deleting it. However, the content won’t be removed from Flockler, and you can create new Slideshows.'
          : 'All websites using this Display layout and Embed code won’t show the content after deleting it. However, the content won’t be removed from Flockler, and you can create new Embed codes.'}
      </>
    </Modal>
  );
};

export default DisplayRemoveConfirmModal;
