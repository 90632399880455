import Button from 'components/Button';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { pathToReviewsNewForm } from 'services/routes';
import { Helmet } from 'react-helmet';

import Page from 'components/Page';
import Heading from 'components/Heading';

const ReviewFormsNewForm = ({ site }: { site: Site }) => {
  const history = useHistory();
  const [formName, setFormName] = useState('');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    history.push(pathToReviewsNewForm(site.site_url, { name: formName }));
  };

  return (
    <Page style={{ maxWidth: '56rem' }}>
      <Helmet>
        <title>Flockler {'\u203A'} Reviews</title>
      </Helmet>

      <Heading level="h1" type="primary">
        Create a new review form
      </Heading>

      <div className="mx-auto max-w-lg animate-fade-in rounded bg-slate-50 p-5 pt-3 text-center">
        <form onSubmit={handleSubmit} className="flex items-end space-x-3">
          <div className="flex-1">
            <label htmlFor="formName" className="flex !h-9 flex-shrink-0 items-center">
              Review form name
            </label>
            <input
              autoFocus
              type="text"
              name="formName"
              defaultValue={formName}
              onChange={(e) => setFormName(e.currentTarget.value)}
              className="input"
              placeholder="Type name of the form…"
            />
          </div>
          <Button type="submit" size="medium" className="w-32">
            Continue
          </Button>
        </form>
      </div>
    </Page>
  );
};

ReviewFormsNewForm.displayName = 'ReviewFormsNewForm';
export default ReviewFormsNewForm;
