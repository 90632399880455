import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Prompt } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import config from 'config';

// Concepts etc
import { pathToReviews } from 'services/routes';
import { embedCode as getEmbedCode } from 'services/reviews';

// Hooks
import useSections from 'hooks/api/useSections';
import useFirstTimeUserExperience from 'hooks/useFirstTimeUserExperience';

// General components
import Page from 'components/Page';
import Button from 'components/Button';

// View specific components
import ReviewSidepanelBlockFields from '../ReviewSidepanelBlockFields';
import ReviewSidepanelBlockSettings from '../ReviewSidepanelBlockSettings';
import ReviewFormPreview from '../ReviewFormPreview';
import { useReviewFormSupportedFields } from 'pages/reviews/hooks/useReviewForms';
import AnimateFadeIn from 'components/AnimateFadeIn';
import ReviewSidepanelBlockThankYouPage from '../ReviewSidepanelBlockThankYouPage';
import PageLoader from 'components/Loader/PageLoader';
import ReviewSidepanelBlockLookAndFeel from '../ReviewSidepanelBlockLookAndFeel';

type SavingChangesStatus = 'initial' | 'saving' | 'saved';

const ReviewFormEdit = ({
  form,
  updateFormData,
  site,
  reviewsServiceDetails,
  mutateForm,
}: {
  form: ReviewForm;
  updateFormData: (form: ReviewForm) => void;
  site: Site;
  reviewsServiceDetails: ReviewsServiceDetails;
  mutateForm: (func: any) => void;
}) => {
  const { sections } = useSections(site.id, { pageSize: null });
  const isFirstTimeUserExperience = useFirstTimeUserExperience();
  const [hiddenFields, setHiddenFields] = useState<ReviewFormConfigFields>({});
  const [focusedField, setFocusedField] = useState<ReviewFormFieldIdentifier | null>(null);
  const [savingChangesStatus, setSavingChangesStatus] = useState<SavingChangesStatus>('initial');
  const saveButtonTextTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [originalForm, setOriginalForm] = useState<ReviewForm>(form);
  const [isCopiedMessageVisible, setCopiedMessageVisible] = useState<boolean>(false);
  const [openBlock, setOpenBlock] = useState<string | undefined>('fields');

  const toggleBlock = (blockId: string) => setOpenBlock(blockId === openBlock ? undefined : blockId);

  const embedCode = form.uuid ? getEmbedCode({ siteUuid: site.uuid, reviewFormUuid: form.uuid }) : '';

  useEffect(() => {
    // Clear the interval when the component unmounts
    return () => {
      if (saveButtonTextTimerRef.current) {
        clearTimeout(saveButtonTextTimerRef.current);
      }
    };
  }, []);

  const saveChanges = async () => {
    if (!form.name) {
      setOpenBlock('settings');
      alert('Please provide a name for the review form.');
      return;
    }

    if (!form.sectionUuid) {
      setOpenBlock('settings');
      alert('Please select a section first.');
      return;
    }

    if (form.config.redirectUrl) {
      setOpenBlock('thank-you');
      try {
        const redirectUrl = new URL(form.config.redirectUrl);
        if (!['http:', 'https:'].includes(redirectUrl.protocol)) {
          throw new Error('Invalid redirect URL');
        }
      } catch {
        alert('Please provide a valid redirect URL.');
        return;
      }
    }

    setSavingChangesStatus('saving');

    mutateForm(async () => {
      let path = `${reviewsServiceDetails.apiBaseUrl}/${site.uuid}/forms`;
      if (form.uuid) path += `/${form.uuid}`;

      try {
        const response = await fetch(path, {
          headers: {
            Authorization: `Bearer ${reviewsServiceDetails.token}`,
          },
          method: form.uuid ? 'PUT' : 'POST',
          body: JSON.stringify(form),
        });

        const json = await response.json();
        if (response.ok) {
          setSavingChangesStatus('saved');
          setOriginalForm(json.reviewForm);
          updateFormData(json.reviewForm);

          saveButtonTextTimerRef.current = setTimeout(() => {
            setSavingChangesStatus('initial');
          }, 1000);
          return json;
        } else {
          alert(json.error.message);
        }
      } catch (e) {
        alert((e as any).message);
      }
      setSavingChangesStatus('initial');
    });
  };

  const handleSectionChange = (sectionUuid: string) => {
    updateFormData({
      ...form,
      sectionUuid,
    });
  };

  const handleNameChange = (name: string) => {
    updateFormData({
      ...form,
      name,
    });
  };

  const handleModerationChange = (value: string) => {
    updateFormData({
      ...form,
      moderated: value === 'inbox',
    });
  };

  const updateField = (fieldId: ReviewFormFieldIdentifier, fieldDetails: ReviewFormFieldDetails) => {
    updateFormData({
      ...form,
      config: {
        ...form.config,
        fields: {
          ...form.config.fields,
          [fieldId]: fieldDetails,
        },
      },
    });
  };

  const supportedFields = useReviewFormSupportedFields();

  // This will be called when the user clicks includes/excludes a field in the sidepanel
  const onToggleField = (fieldIdentifier: ReviewFormFieldIdentifier, orderedFieldIds: ReviewFormFieldIdentifier[]) => {
    toggleField({
      fieldIdentifier,
      form,
      hiddenFields,
      updateFormData,
      setHiddenFields,
      supportedFields,
      orderedFieldIds,
    });
  };

  const reorderFields = (orderedFieldIds: ReviewFormFieldIdentifier[]) => {
    let updatedFields = updateFieldsOrderNumbers({ orderedFieldIds, fields: form.config.fields });

    updateFormData({
      ...form,
      config: {
        ...form.config,
        fields: updatedFields,
      },
    });
  };

  return (
    <Page className="relative !pt-0">
      <Helmet>
        <title>{`Flockler ${'\u203A'} Reviews ${'\u203A'} ${form.uuid ? form.name : 'New'}`}</title>
      </Helmet>
      <Prompt when={!isEqual(originalForm, form)} message="You have unsaved changes, are you sure you want to leave?" />
      {/* Top bar of the editor */}
      <div className={`sticky z-10 ${!isFirstTimeUserExperience ? 'top-24' : ''}`}>
        <AnimateFadeIn animationDelay="0ms">
          <div className={`bg-white ${isFirstTimeUserExperience ? 'h-5' : 'h-10'}`}></div>
          <div className="flex animate-fade-in items-center justify-between gap-4 rounded-lg bg-white opacity-0">
            <div>
              {!isFirstTimeUserExperience ? (
                <Button variant="neutral" size="small" to={pathToReviews(site.site_url)} renderComponent={Link}>
                  Back to list
                </Button>
              ) : (
                <Button
                  variant="neutral"
                  size="small"
                  renderComponent="a"
                  href={`${config.flocklerFeedsUrl}/${site.site_url}?state=reviewFormEditCancel`}
                >
                  Back
                </Button>
              )}
            </div>
            <div className="h-px grow bg-slate-200"></div>
            <div>
              <h1 className="text-3xl font-extrabold">{form.uuid ? 'Edit Review Form' : 'New Review Form'}</h1>
            </div>
            <div className="h-px grow bg-slate-200"></div>
            <div>
              {form.uuid && !isFirstTimeUserExperience && (
                <Button
                  size="small"
                  variant="primary"
                  onClick={() => {
                    setCopiedMessageVisible(true);
                    navigator.clipboard.writeText(embedCode).then(
                      function () {
                        setTimeout(() => {
                          setCopiedMessageVisible(false);
                        }, 1500);
                      },
                      function () {
                        setCopiedMessageVisible(false);
                      }
                    );
                  }}
                  className="w-36"
                >
                  {isCopiedMessageVisible ? 'Copied!' : 'Copy embed code'}
                </Button>
              )}

              {!isFirstTimeUserExperience && (
                <Button
                  size="small"
                  variant="success"
                  onClick={() => saveChanges()}
                  disabled={savingChangesStatus === 'saving'}
                  className="w-20"
                >
                  {savingChangesStatus === 'initial' && (form.uuid ? 'Save' : 'Create')}
                  {savingChangesStatus === 'saving' && (form.uuid ? 'Saving…' : 'Creating…')}
                  {savingChangesStatus === 'saved' && 'Saved!'}
                </Button>
              )}

              {isFirstTimeUserExperience && (
                <Button
                  size="small"
                  variant="success"
                  disabled={savingChangesStatus === 'saving'}
                  onClick={() => {
                    saveChanges().then(() => {
                      window.location.href = `${config.flocklerFeedsUrl}/${site.site_url}?state=reviewFormEditSuccess`;
                    });
                  }}
                >
                  {savingChangesStatus === 'initial' ? 'Continue' : 'Continuing…'}
                </Button>
              )}
            </div>
          </div>
          <div className="pointer-events-none h-10 bg-gradient-to-b from-white"></div>
        </AnimateFadeIn>
      </div>

      {sections.length ? (
        <div className="grid grid-cols-12 gap-5">
          <div className="col-span-4 space-y-2">
            <ReviewSidepanelBlockFields
              form={form}
              setFocusedField={setFocusedField}
              updateField={updateField}
              toggleField={onToggleField}
              reorderFields={reorderFields}
              isOpen={openBlock === 'fields'}
              onToggle={() => toggleBlock('fields')}
            />

            <AnimateFadeIn animationDelay="300ms">
              <ReviewSidepanelBlockThankYouPage
                form={form}
                updateFormData={updateFormData}
                isOpen={openBlock === 'thank-you'}
                onToggle={() => toggleBlock('thank-you')}
              />
            </AnimateFadeIn>

            <AnimateFadeIn animationDelay="300ms">
              <ReviewSidepanelBlockLookAndFeel
                form={form}
                updateFormData={updateFormData}
                isOpen={openBlock === 'look-and-feel'}
                onToggle={() => toggleBlock('look-and-feel')}
              />
            </AnimateFadeIn>

            <AnimateFadeIn animationDelay="400ms">
              <ReviewSidepanelBlockSettings
                form={form}
                sections={sections}
                handleSectionChange={handleSectionChange}
                handleNameChange={handleNameChange}
                handleModerationChange={handleModerationChange}
                isOpen={openBlock === 'settings'}
                onToggle={() => toggleBlock('settings')}
              />
            </AnimateFadeIn>
          </div>
          <div className="col-span-8">
            <AnimateFadeIn animationDelay="400ms">
              <ReviewFormPreview form={form} focusedField={focusedField} />{' '}
            </AnimateFadeIn>
          </div>
        </div>
      ) : (
        <PageLoader />
      )}
    </Page>
  );
};

export default ReviewFormEdit;

// This will be called when the user clicks includes/excludes a field in the sidepanel
const toggleField = ({
  fieldIdentifier,
  form,
  hiddenFields,
  updateFormData,
  setHiddenFields,
  supportedFields,
  orderedFieldIds,
}: {
  fieldIdentifier: ReviewFormFieldIdentifier;
  form: ReviewForm;
  hiddenFields: ReviewFormConfigFields;
  updateFormData: (form: ReviewForm) => void;
  setHiddenFields: React.Dispatch<React.SetStateAction<ReviewFormConfigFields>>;
  supportedFields: ReviewFormConfigFields;
  orderedFieldIds: ReviewFormFieldIdentifier[];
}) => {
  let updatedFields, updatedHiddenFields;

  // Remove field from configuration
  if (form.config.fields[fieldIdentifier]) {
    const { [fieldIdentifier]: removedField, ...theUpdatedFields } = { ...form.config.fields };
    updatedFields = theUpdatedFields;
    updatedHiddenFields = { ...hiddenFields, [fieldIdentifier]: removedField };
  }

  // Add field to configuration from hidden fields
  else if (hiddenFields[fieldIdentifier]) {
    updatedFields = { ...form.config.fields, [fieldIdentifier]: hiddenFields[fieldIdentifier] };
    const { [fieldIdentifier]: removedField, ...theUpdatedHiddenFields } = { ...hiddenFields };
    updatedHiddenFields = theUpdatedHiddenFields;
  }

  // Add field to configuration from default settings
  else {
    updatedFields = { ...form.config.fields, [fieldIdentifier]: supportedFields[fieldIdentifier] };
    updatedHiddenFields = { ...hiddenFields };
  }

  // Update order numbers
  updatedFields = updateFieldsOrderNumbers({ orderedFieldIds, fields: updatedFields });

  updateFormData({ ...form, config: { ...form.config, fields: updatedFields } });
  setHiddenFields(updatedHiddenFields);
};

const updateFieldsOrderNumbers = ({
  orderedFieldIds,
  fields,
}: {
  orderedFieldIds: ReviewFormFieldIdentifier[];
  fields: ReviewFormConfigFields;
}) => {
  let updatedFields = {};
  for (const fieldId in fields) {
    updatedFields = {
      ...updatedFields,
      [fieldId]: {
        ...fields[fieldId],
        orderNumber: orderedFieldIds.indexOf(fieldId as ReviewFormFieldIdentifier) + 1,
      },
    };
  }

  return updatedFields;
};
