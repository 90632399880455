import React, { useState } from 'react';

import { Invitation } from 'concepts/invitation';
import { getGravatarUrl } from 'services/gravatar';

import Button from 'components/Button';
import Modal from 'components/Modal';
import RelativeTime from 'components/RelativeTime';

import styles from './InvitationListItem.module.scss';

type InvitationListItemProps = {
  invitation: Invitation;
  sections: Section[];
  removeAction: any;
};

const InvitationListItem = ({ invitation, sections, removeAction }: InvitationListItemProps) => {
  const [isAboutToRemove, setIsAboutToRemove] = useState(false);
  const [isRemovìng, setIsRemovìng] = useState(false);

  const onAttemptRemove = () => setIsAboutToRemove(true);

  const onCancelRemove = () => {
    if (isRemovìng) {
      return;
    }
    setIsAboutToRemove(false);
  };

  const onConfirmRemove = () => {
    if (isRemovìng) {
      return;
    }

    setIsRemovìng(true);

    /* Add a small timeout so user can see the process started */
    setTimeout(() => {
      removeAction();
    }, 200);
  };

  return (
    <>
      <div className={`${styles.listItem} ${isAboutToRemove ? `${styles.listItemRemoving}` : ''}`}>
        <div className={styles.user}>
          <figure className={styles.avatar}>
            <img
              srcSet={`
                      ${getGravatarUrl(invitation.email, 48)} 48w,
                      ${getGravatarUrl(invitation.email, 96)} 96w
                      `}
              loading="lazy"
              alt=""
            />
          </figure>
          <div className={styles.details}>
            <div className={styles.details__primary}>{invitation.email}</div>
            <div className={styles.details__secondary}>
              Invited <RelativeTime then={invitation.created_at} />
            </div>
          </div>
        </div>

        <div className={styles.role}>
          <div className={styles.invitation__details}>
            <div className={`${styles.details__primary} capitalize`}>{invitation.role}</div>
            <div className={`${styles.details__secondary} truncate`}>{invitation.section_name || 'All sections'}</div>
          </div>
        </div>

        <div className={styles.actions}>
          <Button variant="danger" size="tiny" action={onAttemptRemove}>
            Remove
          </Button>
        </div>
      </div>
      {isAboutToRemove && (
        <Modal
          title={`Are you sure you want to remove this invitation?`}
          actionButtons={[
            <Button
              disabled={isRemovìng}
              key="cancelRemoveInvitation"
              variant="neutral"
              size="medium"
              action={onCancelRemove}
            >
              Cancel
            </Button>,

            <Button
              disabled={isRemovìng}
              key="confirmRemoveInvitation"
              variant="danger"
              size="medium"
              action={onConfirmRemove}
            >
              {isRemovìng ? 'Removing invitation…' : 'Remove invitation'}
            </Button>,
          ]}
          dismissAction={onCancelRemove}
        >
          <p>
            By removing the invitation for <strong>{invitation.email}</strong>, they can no longer accept the invitation
            that has been sent to them. You can reinvite them later if needed.
          </p>
        </Modal>
      )}
    </>
  );
};

export default InvitationListItem;
